import React from "react";
import { useSnapshot } from "valtio";
import { setProxyState, state } from "../utils/state";

const QuantityPicker = ({ quantity, className }) => {
  return (
    <div
      className={
        "relative overflow-hidden rounded-lg border border-darkEarth h-8 w-24 flex " +
        className
      }
    >
      <div
        className="w-1/3 border-r border-darkEarth flex items-center justify-center cursor-pointer text-darkEarth hover:text-pink bg-darkEarth bg-opacity-0 hover:bg-opacity-100"
        style={{ transition: "all .1s linear" }}
        onClick={() =>
          setProxyState("quantity", quantity === 0 ? 0 : quantity - 1)
        }
      >
        <h6 className="font-bold">-</h6>
      </div>
      <div className="w-1/3 border-r border-darkEarth flex items-center justify-center">
        <p className="">{quantity}</p>
      </div>
      <div
        className="w-1/3 flex items-center justify-center cursor-pointer text-darkEarth hover:text-pink bg-darkEarth bg-opacity-0 hover:bg-opacity-100"
        style={{ transition: "all .1s linear" }}
        onClick={() => setProxyState("quantity", quantity + 1)}
      >
        <h6 className="font-bold">+</h6>
      </div>
    </div>
  );
};

export default QuantityPicker;
