import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useSnapshot } from "valtio";
import { setLoadingState, state } from "../../utils/state";
import { useLoader } from "@react-three/fiber";
import { sRGBEncoding, TextureLoader } from "three";
import { LightenDarkenColor } from "../../utils/helpers";

export default function Totem({ hide, ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/totem.glb");
  const stroke = useLoader(TextureLoader, "/Totem_Brush_Black.jpg");
  const {
    color,
    modelLoading: { zamia, bases },
  } = useSnapshot(state);
  const [originalMat, setOriginalMat] = useState(null);
  const [skipFirst, setSkipFirst] = useState(true);

  useEffect(() => {
    if (!skipFirst) {
      if (color === "stroke" && materials && originalMat) {
        materials.Totem_Stroke.map = originalMat;
        materials.Totem_Stroke.needsUpdate = true;
      } else {
        materials.Totem_Stroke.map = null;
        materials.Totem_Stroke.needsUpdate = true;
      }
    }

    setSkipFirst(false);

    return () => setSkipFirst(true);
  }, [color]);

  useEffect(() => {
    stroke.encoding = sRGBEncoding;
    stroke.flipY = false;
    setOriginalMat(stroke);
  }, [stroke]);

  useEffect(() => {
    if (zamia && bases) {
      console.log("Preloading Totem");
      useGLTF.preload("/totem.glb");
    }
  }, [zamia, bases]);

  useEffect(() => {
    if (nodes) {
      console.log("Loaded Totem");
      setLoadingState("totem", true);
    }
  }, [nodes]);

  if (hide) return null;

  return (
    <group ref={group} {...props} dispose={null} rotation={[0, 4.7, 0]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Totem.geometry}
        material={materials.Totem_Stroke}
        material-color={
          color !== "stroke"
            ? color === "#15110A" || color === "#005D6F"
              ? color
              : LightenDarkenColor(color, -60)
            : "#ffffff"
        }
        material-envMapIntensity={0.8}
      />
    </group>
  );
}

// useGLTF.preload('/totem.glb')
