import { snapshot } from "valtio"
import { CartItem } from "./CartItem"
import { displayInfo } from "./displayInfo"
import { ProductInfo } from "./ProductInfo"
import { setProxyState, state } from "./state"

export const getCurrentProductBases = () => {
  const { selectedPot, size } = snapshot(state)
  const currentProductSize = ProductInfo[selectedPot].sizes.filter(productSize => productSize.name === size)[0]

  return currentProductSize ? currentProductSize.bases : null
}

export const getCurrentProductPosition = () => {
  const { selectedPot, selectedBase, size } = snapshot(state)
  const currentProductSize = ProductInfo[selectedPot].sizes.filter(productSize => productSize.name === size)[0]
  const currentProductBase = currentProductSize.bases.filter(baseInfo => baseInfo.name === selectedBase)[0]

  // console.log("Position: ", currentProductBase ? currentProductBase.position : null)

  return currentProductBase ? currentProductBase.position : null
}

export const getCurrentProductSizes = () => {
  const { selectedPot } = snapshot(state)

  return ProductInfo[selectedPot] ? ProductInfo[selectedPot].sizes : null
}

export const getDisplayOptions = option => {
  const { selectedPot, size, selectedBase, withPlant } = snapshot(state)
  const currentProductSize = ProductInfo[selectedPot].sizes.filter(productSize => productSize.name === size)[0]
  const currentProductBase = currentProductSize.bases.filter(baseInfo => baseInfo.name === selectedBase)[0]
  const displayOptions = withPlant ? currentProductBase.displayOptions.withPlant : currentProductBase.displayOptions

  return option ? displayOptions[option] : displayOptions
}

export const getCurrentBaseScale = () => {
  const { selectedPot, size, selectedBase, withPlant } = snapshot(state)
  const currentProductSize = ProductInfo[selectedPot].sizes.filter(productSize => productSize.name === size)[0]
  const currentProductBase = currentProductSize.bases.filter(baseInfo => baseInfo.name === selectedBase)[0]

  return withPlant ? currentProductBase.displayOptions.withPlant.scale : currentProductBase.displayOptions.scale
}

export const getCurrentProductColors = () => {
  const { selectedPot } = snapshot(state)

  return ProductInfo[selectedPot].colors
}

export const getCurrentBaseColors = () => {
  const { selectedBase } = snapshot(state)

  return ProductInfo[selectedBase].colors
}

export const getBaseColors = base => {
  console.log(base, ProductInfo[base].colors, ProductInfo[base].colors.length * 69)
  return ProductInfo[base].colors
}

export const baseTypeAvailable = size => {
  const { selectedBase, selectedPot } = snapshot(state)
  const currentProductSize = ProductInfo[selectedPot].sizes.filter(productSize => productSize.name === size)[0]
  const currentProductBase = currentProductSize.bases.filter(baseInfo => baseInfo.name === selectedBase)

  return currentProductBase.length === 0 ? false : true
}

export const getCurrentBases = (product, size) => {
  return ProductInfo[product].sizes.filter(info => info.name === size)[0]
    ? ProductInfo[product].sizes.filter(info => info.name === size)[0].bases
    : null
}

export const getCurrentSizes = () => {
  const { selectedPot } = snapshot(state)

  return ProductInfo[selectedPot] ? ProductInfo[selectedPot].sizes : null
}

export const stageDisplayName = () => {
  const { currentStage } = snapshot(state)

  switch (currentStage) {
    case "pot":
      return "Maceta"
    case "color":
      return "Colores"
    case "base":
      return "Base"
    case "size":
      return "Tamaño"
    default:
      return "Maceta"
  }
}

export const nextStage = () => {
  const { currentStage, selectedPot } = snapshot(state)

  switch (currentStage) {
    case "pot":
      setProxyState("currentStage", "color")
      break
    case "color":
      if (selectedPot !== "Totem" && selectedPot !== "Bombito" && selectedPot !== "Bombo") {
        setProxyState("currentStage", "size")
      } else {
        setProxyState("currentStage", "confirm")
      }
      break
    case "size":
      setProxyState("currentStage", "base")
      break
    case "base":
      setProxyState("currentStage", "confirm")
      break
    default:
      return
  }
}

export const lastStage = () => {
  const { currentStage, selectedPot } = snapshot(state)

  switch (currentStage) {
    case "color":
      setProxyState("currentStage", "pot")
      break
    case "size":
      setProxyState("currentStage", "color")
      break
    case "base":
      setProxyState("currentStage", "size")
      break
    case "confirm":
      if (selectedPot !== "Totem" && selectedPot !== "Bombito" && selectedPot !== "Bombo") {
        setProxyState("currentStage", "base")
      } else {
        setProxyState("currentStage", "color")
      }
      break
    default:
      return
  }
}

export const updateCamera = (camera, controls, zoomTarget, verticalTarget, zoomSpringValue, verticalSpringValue, done) => {
  if (controls.target.y !== verticalTarget) {
    // console.log('TARGET: ', controls.target.y, verticalSpringValue.get())
    controls.target.y = verticalSpringValue.get()

    controls.update()
  }

  if (camera.zoom !== zoomTarget) {
    console.log("ZOOM: ", camera.zoom, zoomSpringValue.get())
    camera.zoom = zoomSpringValue.get()

    camera.updateProjectionMatrix()
  }

  if (controls.target.y === verticalTarget && camera.zoom === zoomTarget) {
    // console.log("DONE", controls.target, camera.zoom)
    done()
  }
}

export const setGlobalVH = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`)
  document.documentElement.style.setProperty("--variable-vh", `${vh}px`)
}

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min
}

export const parseColorString = color => {
  return color.split("#")[1]
}

export function capitalize(input) {
  var words = input.split(" ")
  var CapitalizedWords = []
  words.forEach(element => {
    CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length))
  })
  return CapitalizedWords.join(" ")
}

export const calculatePrice = () => {
  const { selectedPot, selectedBase, size, quantity } = snapshot(state)

  return (displayInfo[selectedPot].price[size] + displayInfo.bases[selectedBase].price) * quantity
}

export const calculateTotalPrice = () => {
  const { cart } = snapshot(state)
  let currentCart = JSON.parse(cart)
  let price = 0

  if (currentCart.length) {
    currentCart.forEach(item => (price = price + item.price))
  }

  return price
}

export const addCurrentProductToCart = () => {
  const { selectedPot, selectedBase, size, color, cart, quantity } = snapshot(state)
  let newCart = JSON.parse(cart)
  let newItem = new CartItem(selectedPot, color, size, selectedBase, calculatePrice(), quantity)

  newCart.push(newItem)

  setProxyState("cart", JSON.stringify(newCart))
  setProxyState("quantity", 1)
  setProxyState("currentStage", "pot")
}

export const onAddToCart = () => {
  const { currentStage } = snapshot(state)

  if (currentStage === "confirm") {
    addCurrentProductToCart()
  } else {
    setProxyState("currentStage", "confirm")
  }
}

export const removeFromCartByIndex = index => {
  const { cart } = snapshot(state)

  let currentCart = JSON.parse(cart)

  currentCart.splice(index, 1)

  setProxyState("cart", JSON.stringify(currentCart))
}

export function LightenDarkenColor(col, amt) {
  var usePound = false
  if (col[0] == "#") {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000ff) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16)
}
