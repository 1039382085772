export const PotColors = [
  "#15110A",
  "#ffffff",
  "#FED2DA",
  "#F6CA4F",
  "#CA7C5E",
  "#C2C7C8",
  "#FEE2A2",
  "#005D6F",
  "#A1C3A8",
  "#A5E0E8",
  "#C32D2D",
  "#DECAAF",
]

export const ProductInfo = {
  Zamia: {
    colors: ["stroke", ...PotColors],
    sizes: [
      {
        name: "mini",
        scale: [0.45, 0.45, 0.45],
        position: [0, 0, 0],
        bases: [
          {
            displayName: "Sin Base",
            name: "None",
            position: [0, 0, 0],
            displayOptions: {
              zoomLevel: 1,
              withPlant: {
                zoomLevel: 1,
                offset: 0,
              },
            },
          },
          {
            displayName: "Hexa V1",
            name: "H1",
            position: [0, 0.04, 0],
            displayOptions: {
              zoomLevel: 1,
              withPlant: {
                plantPosition: [0, 0.04, 0],
                zoomLevel: 1,
                offset: 0,
              },
            },
          },
          {
            displayName: "Hexa V2",
            name: "H2",
            position: [0, 0.04, 0],
            displayOptions: {
              zoomLevel: 1,
              withPlant: {
                plantPosition: [0, 0.04, 0],
                zoomLevel: 1,
                offset: 0,
              },
            },
          },
          {
            displayName: "Hexa V3",
            name: "H3",
            position: [0, 0.04, 0],
            displayOptions: {
              zoomLevel: 1,
              withPlant: {
                plantPosition: [0, 0.04, 0],
                zoomLevel: 1,
                offset: 0,
              },
            },
          },
          {
            displayName: "Hexa V4",
            name: "H4",
            position: [0, 0.04, 0],
            displayOptions: {
              zoomLevel: 1,
              withPlant: {
                plantPosition: [0, 0.04, 0],
                zoomLevel: 1,
                offset: 0,
              },
            },
          },
          {
            displayName: "Hexa V5",
            name: "H5",
            position: [0, 0.04, 0],
            displayOptions: {
              zoomLevel: 1,
              withPlant: {
                plantPosition: [0, 0.04, 0],
                zoomLevel: 1,
                offset: 0,
              },
            },
          },
        ],
      },
      {
        name: "small",
        scale: [0.79, 0.79, 0.79],
        position: [0, 0.6, 0],
        bases: [
          {
            displayName: "Sin Base",
            name: "None",
            position: [0, 0, 0],
            displayOptions: {
              zoomLevel: 1,
              withPlant: {
                plantPosition: [0, -0.01, 0],
                plantScale: [0.75, 0.75, 0.75],
                zoomLevel: 1,
                offset: 0.35,
              },
            },
          },
          {
            displayName: "Geo",
            name: "GEO",
            position: [0, 0.6, 0],
            displayOptions: {
              zoomLevel: 0.6,
              offset: 0.45,
              withPlant: {
                plantPosition: [0, 0.6, 0],
                plantScale: [0.75, 0.75, 0.75],
                zoomLevel: 0.6,
                offset: 0.65,
              },
            },
          },
          {
            displayName: "Tripié",
            name: "Tripie",
            position: [0, 0.375, 0],
            displayOptions: {
              zoomLevel: 0.8,
              scale: [0.75, 0.75, 0.75],
              offset: 0.4,
              withPlant: {
                plantPosition: [0, 0.35, 0],
                plantScale: [0.75, 0.75, 0.75],
                scale: [0.75, 0.75, 0.75],
                zoomLevel: 0.6,
                offset: 0.39,
              },
            },
          },
          {
            displayName: "Banca",
            name: "Bench",
            position: [0, 0.39, 0],
            displayOptions: {
              zoomLevel: 0.8,
              offset: 0.39,
              withPlant: {
                plantPosition: [0, 0.35, 0],
                plantScale: [0.75, 0.75, 0.75],
                zoomLevel: 0.6,
                offset: 0.39,
              },
            },
          },
        ],
      },
      {
        name: "med",
        scale: [1, 1, 1],
        position: [0, 0.45, 0],
        bases: [
          {
            displayName: "Sin",
            name: "None",
            position: [0, 0, 0],
            displayOptions: {
              zoomLevel: 1,
              withPlant: {
                plantScale: [0.99, 0.99, 0.99],
                zoomLevel: 0.9,
                offset: 0.4,
              },
            },
          },
          {
            displayName: "Geo",
            name: "GEO",
            position: [0, 0.5, 0],
            displayOptions: {
              zoomLevel: 0.7,
              offset: 0.45,
              withPlant: {
                plantScale: [0.99, 0.99, 0.99],
                plantPosition: [0, 0.5, 0],
                zoomLevel: 0.6,
                offset: 0.6,
              },
            },
          },
          {
            displayName: "Tripié",
            name: "Tripie",
            position: [0, 0.5, 0],
            displayOptions: {
              zoomLevel: 0.7,
              offset: 0.45,
              withPlant: {
                plantScale: [0.99, 0.99, 0.99],
                plantPosition: [0, 0.5, 0],
                zoomLevel: 0.6,
                offset: 0.65,
              },
            },
          },
          {
            displayName: "Banca",
            name: "Bench",
            position: [0, 0.39, 0],
            displayOptions: {
              zoomLevel: 0.8,
              offset: 0.39,
              withPlant: {
                plantScale: [0.99, 0.99, 0.99],
                plantPosition: [0, 0.4, 0],
                zoomLevel: 0.6,
                offset: 0.6,
              },
            },
          },
        ],
      },
      {
        name: "large",
        scale: [1.35, 1.35, 1.35],
        position: [0, 0.25, 0],
        bases: [
          {
            displayName: "Sin Base",
            name: "None",
            position: [0, 0, 0],
            displayOptions: {
              zoomLevel: 1,
              withPlant: {
                zoomLevel: 0.7,
                offset: 0.6,
              },
            },
          },
          {
            displayName: "Geo",
            name: "GEO",
            position: [0, 0.26, 0],
            displayOptions: {
              zoomLevel: 0.8,
              offset: 0.39,
              withPlant: {
                plantPosition: [0, 0.2, 0],
                zoomLevel: 0.6,
                offset: 0.6,
              },
            },
          },
        ],
      },
    ],
  },
  Totem: {
    colors: ["stroke", ...PotColors],
    sizes: [
      {
        name: "standard",
        scale: [1, 1, 1],
        position: [0, 0, 0],
        bases: [
          {
            displayName: "Sin Base",
            name: "None",
            position: [0, 0, 0],
            displayOptions: {
              zoomLevel: 1.5,
              offset: 0.1,
              withPlant: {
                plantPosition: [0, 0.04, 0],
                // plantScale: [0.06, 0.06, 0.06],
                zoomLevel: 1.5,
                offset: 0.15,
              },
            },
          },
        ],
      },
    ],
  },
  Bombo: {
    colors: PotColors,
    sizes: [
      {
        name: "standard",
        scale: [1, 1, 1],
        position: [0, 0, 0],
        bases: [
          {
            displayName: "Sin Base",
            name: "None",
            position: [0, 0, 0],
            displayOptions: {
              zoomLevel: 1.5,
              offset: 0.1,
              withPlant: {
                plantPosition: [0, 0.05, 0],
                plantScale: [0.8, 0.8, 0.8],
                zoomLevel: 2,
                offset: 0.15,
              },
            },
          },
        ],
      },
    ],
  },
  Bombito: {
    colors: PotColors,
    sizes: [
      {
        name: "standard",
        scale: [1, 1, 1],
        position: [0, 0, 0],
        bases: [
          {
            displayName: "Sin Base",
            name: "None",
            position: [0, 0, 0],
            displayOptions: {
              zoomLevel: 1.5,
              offset: 0.1,
              withPlant: {
                plantPosition: [0, 0.02, 0],
                plantScale: [0.6, 0.6, 0.6],
                zoomLevel: 1.5,
                offset: 0.15,
              },
            },
          },
        ],
      },
    ],
  },
  GEO: {
    colors: ["#070707", "#FFFFFF"],
  },
  Tripie: {
    colors: PotColors,
  },
  Bench: {
    colors: [],
  },
  None: {
    colors: [],
  },
  H1: {
    colors: [],
  },
  H2: {
    colors: [],
  },
  H3: {
    colors: [],
  },
  H4: {
    colors: [],
  },
  H5: {
    colors: [],
  },
}
