import React, { useEffect, useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { useSpring } from "@react-spring/core"
import { getDisplayOptions } from "../../utils/helpers"
import { animated } from "@react-spring/three"
import { useSnapshot } from "valtio"
import { setLoadingState, state } from "../../utils/state"

export default function PlantMedium({ loaded, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF("/plants_small.glb")
  const {
    size,
    selectedBase,
    withPlant,
    selectedPot,
    modelLoading: { zamia, bases, plants },
  } = useSnapshot(state)

  const [{ pos, scale }, animate] = useSpring(() => ({
    pos: getDisplayOptions("position") || [0, 0, 0],
    scale: withPlant ? getDisplayOptions("plantScale") || [1, 1, 1] : [0, 0, 0],
    delay: 500,
    config: { mass: 10, tension: 1000, friction: 200, precision: 0.00001 },
  }))

  useEffect(() => {
    // console.log(getDisplayOptions('plantPosition'), withPlant ? [1, 1, 1] : [0, 0, 0])
    animate({
      pos: getDisplayOptions("plantPosition") || [0, 0, 0],
      scale: withPlant ? getDisplayOptions("plantScale") || [1, 1, 1] : [0, 0, 0],
    })
  }, [withPlant, size, selectedBase, selectedPot])

  useEffect(() => {
    if (zamia && !plants) {
      console.log("Preloading plants")
      useGLTF.preload("/plants_small.glb")
    }
  }, [zamia, bases])

  useEffect(() => {
    if (nodes) {
      console.log("Loaded plant")
      setLoadingState("plants", true)
    }
  }, [nodes])

  return (
    <group ref={group} {...props} dispose={null}>
      <animated.group scale={scale} position={pos}>
        <mesh
          geometry={nodes.Plane024.geometry}
          material={materials.M_Fern_a1}
          material-envMapIntensity={0.8}
          visible={withPlant && (size === "mini" || size === "standard")}
        />
        <mesh
          geometry={nodes.Plane024_1.geometry}
          material={materials.M_Pot_04}
          material-envMapIntensity={0.8}
          visible={withPlant && (size === "mini" || size === "standard")}
        />
        <mesh
          geometry={nodes.Plane069.geometry}
          material={materials.M_Monstera_g1}
          material-envMapIntensity={0.8}
          visible={withPlant && (size === "med" || size === "small")}
        />
        <mesh
          geometry={nodes.Plane069_1.geometry}
          material={materials.M_Pot_02}
          material-envMapIntensity={0.8}
          visible={withPlant && (size === "med" || size === "small")}
        />
        <mesh
          geometry={nodes.Plane067.geometry}
          material={materials.M_Arrowhead_e1}
          material-envMapIntensity={0.8}
          material-alphaTest={0.5}
          material-depthWrite={true}
          visible={withPlant && size === "large"}
        />
        <mesh
          geometry={nodes.Plane067_1.geometry}
          material={materials.Pot_01}
          material-envMapIntensity={0.8}
          visible={withPlant && size === "large"}
        />
      </animated.group>
    </group>
  )
}

// useGLTF.preload("/plants_small.glb");
