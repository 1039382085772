import React, { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useSnapshot } from "valtio";
import { useSpring, animated } from "@react-spring/three";
import { ProductInfo } from "../../utils/ProductInfo";
import { setLoadingState, state } from "../../utils/state";
import {
  getCurrentProductPosition,
  LightenDarkenColor,
} from "../../utils/helpers";
import { useLoader } from "@react-three/fiber";
import { sRGBEncoding, TextureLoader } from "three";

const returnSize = (size) => {
  return ProductInfo.Zamia.sizes.filter((info) => info.name === size)[0].scale;
};

export default function Zamia({ loaded, ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/zamia_pot.glb");
  const stroke = useLoader(TextureLoader, "/Zamia_Brush_Stroke.jpg");
  const { size, selectedBase, color, withPlant } = useSnapshot(state);
  const [strokeMat, setStrokeMat] = useState(null);
  const [afterBases, setAfterBases] = useState(false);

  const [{ scale, pos }, animate] = useSpring(() => ({
    scale: returnSize(size),
    pos: [0, 0, 0],
    onRest: () => console.log("Left Pot at position ", pos.get()),
    config: { mass: 10, tension: 1000, friction: 200, precision: 0.00001 },
  }));

  useEffect(() => {
    animate({ scale: returnSize(size) });
    animate({ pos: getCurrentProductPosition() });
  }, [size, selectedBase, withPlant]);

  useEffect(() => {
    console.log("Loaded Main");
    setLoadingState("zamia", true);
  }, [nodes]);

  useEffect(() => {
    if (color === "stroke" && materials && strokeMat) {
      materials.Paint.map = strokeMat;
      materials.Paint.needsUpdate = true;
    } else {
      materials.Paint.map = null;
      materials.Paint.needsUpdate = true;
    }
  }, [color]);

  useEffect(() => {
    stroke.encoding = sRGBEncoding;
    setStrokeMat(stroke);
  }, [stroke]);

  useEffect(() => {
    setTimeout(() => {
      setAfterBases(true);
    }, 1000);
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      {afterBases && (
        <mesh
          geometry={nodes.placeholder.geometry}
          material={nodes.placeholder.material}
          position={[0, 0.4, 0]}
          scale={[0.2, 0.4, 0.19]}
          visible={false}
        />
      )}
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.Pot.geometry}
        material={materials.Paint}
        material-color={
          color === "stroke"
            ? "#ffffff"
            : color === "#15110A" || color === "#005D6F"
            ? color
            : LightenDarkenColor(color, -60)
        }
        material-envMapIntensity={0.8}
        scale={scale}
        position={pos}
        rotation={[0, -89.52, 0]}
      />
    </group>
  );
}

useGLTF.preload("/zamia_pot.glb");
