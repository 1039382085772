export const displayInfo = {
  Zamia: {
    description:
      "Debido a la naturaleza artesanal de las macetas, cada una es única y puede tener variaciones en la superficie. Creemos que esto se suma a la autenticidad y el encanto de un producto hecho a mano. \n ¡Esperamos que te gusten tanto como a nosotros!",
    price: {
      mini: 15,
      small: 25,
      med: 40,
      large: 55,
    },
  },
  Totem: {
    description: "El totem es la maceta perfecto por plantos grandes como la monsterra. La forma redonda parece muy precioso en todos los espacios.",
    price: {
      standard: 15,
    },
  },
  Bombo: {
    description: "El Bombo es la maceta perfecto por plantos grandes como la monsterra. La forma redonda parece muy precioso en todos los espacios.",
    price: {
      standard: 25,
    },
  },
  Bombito: {
    description:
      "El Bombito es la maceta perfecto por plantos grandes como la monsterra. La forma redonda parece muy precioso en todos los espacios.",
    price: {
      standard: 10,
    },
  },
  bases: {
    None: {
      price: 0,
      name: "Sin",
    },
    GEO: {
      price: 15,
      name: "GEO",
    },
    Tripie: {
      price: 15,
      name: "Tripié",
    },
    Bench: {
      price: 20,
      name: "Banca",
    },
    H1: {
      price: 12,
      name: "Hexa",
    },
    H2: {
      price: 12,
      name: "Hexa",
    },
    H3: {
      price: 12,
      name: "Hexa",
    },
    H4: {
      price: 12,
      name: "Hexa",
    },
    H5: {
      price: 12,
      name: "Hexa",
    },
  },
  colorDictionary: {
    stroke: "Stroke",
    "#15110A": "Black Magic",
    "#ffffff": "Extra White",
    "#FED2DA": "Impatient Petal",
    "#F6CA4F": "Gambol Gold",
    "#CA7C5E": "Cavern Clay",
    "#C2C7C8": "Grey Screen",
    "#FEE2A2": "Optimistic Yellow",
    "#005D6F": "Oceanside",
    "#A1C3A8": "Agate Green",
    "#A5E0E8": "Minor Blue",
    "#C32D2D": "Red Bay",
    "#DECAAF": "Nomadic Dessert",
  },
  sizeDictionary: {
    mini: {
      name: "Minipot",
      dimensions: "15cm",
    },
    small: {
      name: "Small",
      dimensions: "25cm",
    },
    med: {
      name: "Medium",
      dimensions: "30cm",
    },
    large: {
      name: "Large ",
      dimensions: "40cm",
    },
    standard: {
      name: "Standard",
      dimensions: "15cm",
    },
  },
}
