import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import gsap from "gsap"
import { SplitText } from "gsap/SplitText"
import LogoShort from "../components/Icons/LogoShort"
import { Colors } from "../utils/colors"

import gridM1 from "../assets/grid/mobile/1.jpg"
import gridM2 from "../assets/grid/mobile/2.jpg"
import gridM3 from "../assets/grid/mobile/3.jpg"
import gridM4 from "../assets/grid/mobile/4.jpg"
import gridM5 from "../assets/grid/mobile/5.jpg"
import gridM6 from "../assets/grid/mobile/6.jpg"
import gridM7 from "../assets/grid/mobile/7.jpg"
import gridM8 from "../assets/grid/mobile/8.jpg"
import gridM9 from "../assets/grid/mobile/9.jpg"

import logo from "../assets/LOGOTIPO CASSU 2019.svg"
import { Logo } from "../Scene"
import Loader from "react-loader-spinner"
import Image from "./Image"

gsap.registerPlugin(SplitText)

let splitTextOut = null

const Headline = styled.h1`
  font-size: 290px;
  line-height: 304px;

  @media (max-width: 640px) {
    font-size: 24vw;
    line-height: 26vw;
  }
`
const Quote = styled.h2`
  font-size: 44px;
  line-height: 61px;
  max-width: 705px;
  @media (max-width: 640px) {
    font-size: 9vw;
    line-height: 13vw;
  }
`

const ShopIntro = ({ done, introDone, loading }) => {
  const containerRef = useRef()
  const textRef = useRef()
  const gridRef = useRef()
  const quoteRef = useRef()
  const buttonRef = useRef()
  const whiteLogoRef = useRef()
  const blackLogoRef = useRef()
  const columnRefs = useRef([null, null, null, null, null])
  const { current: timeline } = useRef(new gsap.timeline({ paused: true }))
  const [finished, setFinished] = useState(false)

  useEffect(() => {
    splitTextOut = new SplitText(textRef.current, {
      linesClass: "line",
      charsClass: "chars",
    })

    gsap.set(containerRef.current, { backgroundColor: Colors.darkEarth })
    gsap.set(splitTextOut.chars, { x: 95, z: -285, rotateY: 90, autoAlpha: 0 })
    gsap.set(gridRef.current, { scale: window.innerWidth <= 640 ? 1.4 : 1.8, y: window.innerWidth <= 640 ? 0 : 360, autoAlpha: 0, rotation: 0.01 })
    gsap.set(columnRefs.current[0], { y: 260, rotation: 0.01 })
    gsap.set(columnRefs.current[1], { y: 90, rotation: 0.01 })
    gsap.set(columnRefs.current[2], { y: 130, rotation: 0.01 })
    gsap.set(columnRefs.current[3], { y: 30, rotation: 0.01 })
    gsap.set(columnRefs.current[4], { y: 320, rotation: 0.01 })
    gsap.set(quoteRef.current, { autoAlpha: 0, y: 20 })

    timeline
      .addLabel("start", 0)
      .to(gridRef.current, { autoAlpha: 0.4, delay: 0.8, duration: 2 }, "start+=0.2")
      .to(columnRefs.current[1], { y: -40, duration: 6 }, "start")
      .to(columnRefs.current[2], { y: 0, duration: 6 }, "start+=0.2")
      .to(columnRefs.current[3], { y: -100, duration: 6 }, "start+=0.4")
      .to(splitTextOut.chars, { stagger: 0.1, x: 0, z: 0, rotateY: 0, autoAlpha: 1, delay: 0.8, duration: 1, ease: "power2.out" }, "start+=0.5")
      .to(splitTextOut.chars, { stagger: 0.05, x: 95, z: -285, rotateY: 90, autoAlpha: 0, delay: 1.2 }, "start+=2.2")
      .to(gridRef.current, { autoAlpha: 1, scale: 1, y: 200, duration: 3, ease: "power3.inOut" }, "start+=3.5")
      .to(containerRef.current, { backgroundColor: Colors.pink, ease: "power3.inOut" }, "start+=3.5")
      .to(buttonRef.current, { color: Colors.darkEarth, ease: "power3.inOut" }, "start+=3.5")
      .to(whiteLogoRef.current, { autoAlpha: 0, ease: "power3.inOut" }, "start+=3.5")
      .to(columnRefs.current[0], { y: -20, duration: 5, ease: "power1.inOut" }, "start+=2.5")
      .to(columnRefs.current[1], { y: -240, duration: 5, ease: "power1.inOut" }, "start+=2.5")
      .to(columnRefs.current[2], { y: -180, duration: 5, ease: "power1.inOut" }, "start+=2.5")
      .to(columnRefs.current[3], { y: -280, duration: 5, ease: "power1.inOut" }, "start+=2.5")
      .to(columnRefs.current[4], { y: -0, duration: 5, ease: "power1.inOut" }, "start+=2.5")
      .to(gridRef.current, { autoAlpha: 0, duration: 2 }, "start+=7")
      .to(blackLogoRef.current, { autoAlpha: 1 }, "start+=9")
      .to(
        quoteRef.current,
        {
          y: 0,
          duration: 1,
        },
        "start+=10"
      )
      .to(
        quoteRef.current,
        {
          autoAlpha: 1,
          duration: 2,
          onComplete: () => {
            timeline.pause()
            setFinished(true)
          },
        },
        "start+=10"
      )
      .play()
  }, [timeline])

  const finalFade = immediate => {
    if (!timeline.paused()) return

    gsap.to(containerRef.current, { autoAlpha: 0, duration: 1.6, delay: immediate ? 0 : 0.8, onStart: () => setTimeout(() => done(), 1000) })
  }

  useEffect(() => {
    if (!loading) {
      finalFade()
    }
  }, [loading, finished])

  return (
    <div
      ref={containerRef}
      className={"absolute z-50 left-0 top-0 w-screen h-initial-height flex items-center justify-center " + (introDone ? "pointer-events-none" : "")}
    >
      <LogoShort forwardedRef={whiteLogoRef} className="absolute top-6 left-0 right-0 m-auto" />
      <Logo ref={blackLogoRef} className="absolute top-6 left-0 right-0 m-auto opacity-0" alt="logo" src={logo} />
      <Headline ref={textRef} className="text-pink text-left uppercase absolute z-10 top-1/2 transform -translate-y-1/2 left-5 sm:left-32 m-auto">
        <span className="eiko font-extralight">Hello</span>
        <br />
        Cassù
      </Headline>
      <div ref={gridRef} className="absolute w-xl sm:w-screen h-initial-height flex items-center justify-center space-x-8 sm:space-x-16 opacity-0">
        <div ref={ref => (columnRefs.current[0] = ref)} className="relative flex flex-col space-y-8 sm:space-y-10">
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM7} />
          </div>
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM5} />
          </div>
        </div>
        <div ref={ref => (columnRefs.current[1] = ref)} className="relative flex flex-col space-y-8 sm:space-y-10">
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM3} />
          </div>
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM4} />
          </div>
        </div>
        <div ref={ref => (columnRefs.current[2] = ref)} className="relative flex flex-col space-y-8 sm:space-y-10">
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM2} />
          </div>
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM6} />
          </div>
        </div>
        <div ref={ref => (columnRefs.current[3] = ref)} className="relative flex flex-col space-y-8 sm:space-y-10">
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM1} />
          </div>
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM8} />
          </div>
        </div>
        <div ref={ref => (columnRefs.current[4] = ref)} className="relative flex flex-col space-y-8 sm:space-y-10">
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM9} />
          </div>
          <div className="rounded-xl relative overflow-hidden">
            <Image simple className="w-full" alt="cassu" src={gridM1} />
          </div>
        </div>
      </div>
      <Quote ref={quoteRef} className="eiko font-extralight uppercase w-full px-5 opacity-0">
        Let’s Introduce Nature Into our lifes.
      </Quote>

      {finished && loading && (
        <div className="absolute left-0 right-0 bottom-16 m-auto text-pink cursor-pointer w-full flex justify-center">
          <Loader type="Oval" color={Colors.darkEarth} height={30} width={30} />
        </div>
      )}
      <p
        ref={buttonRef}
        onClick={
          loading
            ? null
            : () => {
                timeline.pause()
                finalFade(true)
              }
        }
        className="absolute left-0 right-0 bottom-6 m-auto text-pink cursor-pointer"
      >
        {loading ? "Loading Experience" : "Skip To Shop"}
      </p>
    </div>
  )
}

export default ShopIntro
