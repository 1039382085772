export class CartItem {
  constructor(pot, color, size, base, price, quantity) {
    this.pot = pot;
    this.color = color;
    this.size = size;
    this.base = base;
    this.price = price;
    this.quantity = quantity;
  }
}
