import styled, { css } from "styled-components/macro"
import { Colors } from "../../utils/colors"
import { PotColors } from "../../utils/ProductInfo"

const returnStageHeight = stage => {
  switch (stage) {
    case "pot":
      return 150
    case "color":
      return 150
    case "size":
      return 130
    case "base":
      return 150
    case "confirm":
      return 230
    default:
      return 150
  }
}

export const OptionsContainerMobile = styled.div`
  position: absolute;
  top: 93%;
  left: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 300px;
  background: ${({ $hidden }) => ($hidden ? Colors.highlight : "rgba(255, 255, 255, 0.8)")};
  backdrop-filter: blur(18px);
  border-radius: 20px 20px 0px 0px;
  padding: 0px 12px;
  transform: translateY(0%);
  touch-action: pan-y;

  transition: transform 0.8s 0s cubic-bezier(0.425, 0.005, 0, 1), background-color 0.8s 0s cubic-bezier(0.425, 0.005, 0, 1);

  &.revealed {
    transform: translateY(-${({ $stage, $hidden }) => ($hidden ? 25 : returnStageHeight($stage))}px);
  }

  & p {
    cursor: pointer;
    position: relative;
  }

  & p::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0px;
    height: 2px;
    width: 100%;
    background-color: ${PotColors[8]};
    opacity: 0;

    transition: opacity 0.4s;
  }

  & p.selected::after {
    opacity: 1;
  }

  & p.pot-type::after {
    opacity: 1;
    background-color: ${PotColors[3]};
  }

  & #start-mobile {
    background: ${Colors.highlight};
    color: white;
    position: absolute;
    top: -23px;
    right: 0;
    left: 0;
    margin: auto;
    width: 144px;
    transition: opacity 0.8s cubic-bezier(0.425, 0.005, 0, 1);
  }

  & #start-mobile.hidden {
    opacity: 0;
  }

  & h6 {
    color: ${Colors.darkEarth};
  }

  & .selected h6 {
    color: white;
  }

  @media (max-width: 1200px) {
    display: flex;
  }
`

export const MobileRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;

  &.fit {
    margin: 0px 20px;
  }

  &::-webkit-scrollbar,
  & *::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  &.scrollable::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20px;
    z-index: 10;
    background: red;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.8) 80%);
  }

  &.scrollable::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20px;
    z-index: 10;
    background: red;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.8) 80%);
  }
`

export const ColorOptionMobile = styled.div`
  box-shadow: 0px 7px 11px rgba(57, 44, 24, 0.2);

  &::after {
    content: "";
    position: absolute;
    width: 38px;
    height: 38px;
    border-width: 1px;
    border-color: ${({ $selected }) => ($selected ? Colors.darkEarth : "transparent")};
    border-radius: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export const OptionsBarOuter = styled.div`
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.8s 0.5s cubic-bezier(0.425, 0.005, 0, 1);

  &.revealed {
    opacity: 1;
  }
`

export const BaseContainer = styled.div`
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }

  & *::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  &.scrollable::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20px;
    z-index: 10;
    background: red;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.8) 80%);
  }

  &.scrollable::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20px;
    z-index: 10;
    background: red;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.8) 80%);
  }
`

export const Button = styled.div`
  position: relative;
  overflow: hidden;
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
  width: 35px;
  height: 35px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bg }) => bg || "lightgray"};
  margin: 8px;
  cursor: pointer;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-position: -112px 118px;
  border: 2.5px solid #ffffff;

  transition: 0.4s cubic-bezier(0.425, 0.005, 0, 1);

  &:hover {
    filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.2));
  }

  ${({ size }) =>
    size &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: ${Colors.pink};
        border-radius: 500px;
        width: 73%;
        height: 73%;
      }
    `}

  ${({ size }) =>
    size === "mini" &&
    css`
      &::after {
        width: 20%;
        height: 20%;
      }
    `}

${({ size }) =>
    size === "small" &&
    css`
      &::after {
        width: 50%;
        height: 50%;
      }
    `}

${({ size }) =>
    size === "large" &&
    css`
      &::after {
        display: none;
      }
    `}
`

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 8px 10px;
  box-shadow: -3px 3px 11px -4px rgb(82 82 82);

  transition: opacity 0.5s cubic-bezier(0.425, 0.005, 0, 1);

  & > * {
    margin: 0px;
    font-weight: 400;
  }

  & img {
    width: 19px;
  }

  & img.close {
    width: 8px;
  }

  & img.ar {
    width: 22px;
  }
`

export const ARButton = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 500px;
  background-color: rgb(238 238 238);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25%;
  right: 0;
  left: 0;
  margin: auto;
  box-shadow: -4px 4px 23px -4px rgb(82 82 82);

  & * {
    width: 70%;
    margin-left: 1px;
  }
`

export const OptionsBar = styled(MobileRow)`
  position: relative;
  height: 60px;
  width: 100%;
  background: transparent;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 14px 0px;

  transition: background 0.4s cubic-bezier(0.425, 0.005, 0, 1);

  & #next {
    right: 10px;
    border-radius: 500px;
    /* position: absolute; */
    background: ${Colors.highlight};

    transition: opacity 0.2s 0s cubic-bezier(0.425, 0.005, 0, 1);
  }

  & #back {
    left: 10px;
    border-radius: 500px;
    /* position: absolute; */
    transform: rotateZ(180deg);
    background: ${Colors.highlight};

    transition: opacity 0.2s 0s cubic-bezier(0.425, 0.005, 0, 1);
  }
`

export const AddToCartButton = styled.div`
  position: absolute;
  /* right: -72px; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  right: -870px;
  left: 0;
  margin: auto;
  color: white;

  &.selected {
    width: 270px;
    height: 60px;
    background: ${Colors.highlight};
    backdrop-filter: blur(18px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 70px;
    right: 0px;
  }

  & h5 {
    opacity: 0;

    transition: 0.2s 0s cubic-bezier(0.425, 0.005, 0, 1);
  }

  &.selected h5 {
    opacity: 1;

    transition: 0.7s 0.2s cubic-bezier(0.425, 0.005, 0, 1);
  }

  & img {
    position: absolute;
  }

  &.selected img {
    opacity: 0;
  }

  transition: 0.7s cubic-bezier(0.425, 0.005, 0, 1);
`

export const PotOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;

  ${({ $selected }) =>
    $selected &&
    css`
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(40px);
      box-shadow: 0px 7px 11px rgba(57, 44, 24, 0.2);
    `}
  cursor: pointer;

  border-radius: 10px;
  margin: 0px 7.5px 23px;

  transition: background 0.4s cubic-bezier(0.425, 0.005, 0, 1);

  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  & h6 {
    font-size: 14px;
  }

  & img {
    height: 41px;
    margin: 3px 0px;
  }
`

export const BaseColors = styled(MobileRow)`
  width: 0px;
  max-width: 414px;
  max-height: 51px;
  height: 51px;

  transition: width 0.8s;
  transition-timing-function: cubic-bezier(0.425, 0.005, 0, 1);
`

export const BaseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 56px;
  height: 42px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(60px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 57px;
  border: 1px solid #eae0de;
  color: ${Colors.darkEarth};
  cursor: pointer;

  transition: background 0.5s, color 0.5s;
  transition-timing-function: cubic-bezier(0.425, 0.005, 0, 1);

  &.selected {
    background: ${Colors.darkEarth};
    color: white !important;
  }
`

export const BaseOption = styled.div`
  & ${BaseColors} ${Button} {
    opacity: 0;
    margin: 0px 12px;

    transition: 0.1s 0s;
  }

  &.selected ${BaseButton} {
    background: ${Colors.darkEarth};
    color: white !important;
  }

  &.selected ${BaseColors} ${Button} {
    opacity: 1;

    transition: opacity 0.6s 0.9s;
  }
`

export const SelectionIndicator = styled.div`
  position: absolute;
  left: 29px;
  top: 1px;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border-radius: 500px;
  background-color: ${({ bg }) => bg || "transparent"};

  ${({ size }) =>
    size &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 1px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        border-radius: 500px;
        background-color: ${({ bg }) => (bg ? Colors.pink : "transparent")};

        transition: transform 0.7s cubic-bezier(0.425, 0.005, 0, 1);
      }
    `}

  ${({ size }) =>
    size === "med" &&
    css`
      &::after {
        transform: scale(0.7);
      }
    `}
  
  ${({ size }) =>
    size === "small" &&
    css`
      &::after {
        transform: scale(0.5);
      }
    `}
  
  ${({ size }) =>
    size === "mini" &&
    css`
      &::after {
        transform: scale(0.2);
      }
    `}
`

export const MovingBackground = styled.div`
  position: absolute;
  left: 18px;
  width: 171px;
  height: 48px;
  border-radius: 70px;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  background-color: ${({ bg }) => bg || Colors.darkEarth};
  color: white;
  transform: translateX(0px);

  ${({ stage }) =>
    (stage === null || stage === "confirm") &&
    css`
      background-color: transparent;
      box-shadow: none;
    `}

  ${({ stage }) =>
    stage === "color" &&
    css`
      transform: translateX(191px);
    `}

${({ stage }) =>
    stage === "size" &&
    css`
      transform: translateX(382px);
    `}

${({ stage }) =>
    stage === "base" &&
    css`
      transform: translateX(573px);
    `}

transition: transform .7s cubic-bezier(.425, .005, 0, 1), background-color .7s cubic-bezier(.425, .005, 0, 1);
`

export const StartPrompt = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -208px;
  top: -81px;
  opacity: 1;
  pointer-events: none;

  transition: opacity 0.7s cubic-bezier(0.425, 0.005, 0, 1);

  & p {
    margin: 0;
  }

  & img {
    margin-left: 90px;
  }

  &.hide {
    opacity: 0;
  }
`
