import React, { Suspense, useEffect, useRef, useState } from "react"
import { Canvas } from "@react-three/fiber"
import { Stage } from "@react-three/drei"
import ZamiaBases from "../models/ZamiaBases"
import Zamia from "../models/Zamia"
import Totem from "../../components/models/Totem"
import Bombito from "../../components/models/Bombito"
import PlantMedium from "../../components/models/PlantMedium"
import ControlCamera from "../ControlCamera"
import { useSnapshot } from "valtio"
import { state } from "../../utils/state"
import ModelViewer from "../ModelViewer"
import gsap from "gsap"
import { SplitText } from "gsap/SplitText"
import { BottomSpacer, VisualizerWrapper } from "./Customizer.styles"
import CustomizerUI from "./components/CustomizerUI"
import Bombo from "../models/Bombo"

gsap.registerPlugin(SplitText)

let splitTextOut = null

function Customizer({ withBase, setShowLoading, activated, started, ...props }) {
  const ref = useRef()
  const textRef = useRef()
  const {
    selectedPot,
    modelLoading: { zamia, bases, plants },
    pickerHidden,
    cartOpen,
  } = useSnapshot(state)
  const [loadCount, setLoadCount] = useState(0)

  const selectModel = () => {
    switch (selectedPot) {
      case "Zamia":
        return (
          <>
            <Zamia withBase={withBase} loaded={() => setLoadCount(loadCount + 1)} />
          </>
        )
      case "Totem":
        return zamia && bases && <Totem />
      case "Bombito":
        return zamia && bases && <Bombito />
      case "Bombo":
        return zamia && bases && <Bombo />
      default:
        return (
          <>
            <Zamia withBase={withBase} loaded={() => setLoadCount(loadCount + 1)} />
          </>
        )
    }
  }

  useEffect(() => {
    if (zamia && plants) {
      setShowLoading(false)
    }
  }, [zamia, plants])

  useEffect(() => {
    splitTextOut = new SplitText(textRef.current, {
      linesClass: "line",
      charsClass: "chars",
    })
  }, [])

  return (
    <VisualizerWrapper
      characters={splitTextOut ? splitTextOut.chars : []}
      className={(started ? "mobile-active" : "null") + (pickerHidden ? " hidden" : "")}
      cartOpen={cartOpen}
    >
      <CustomizerUI activated={activated} started={started} splitTextOut={splitTextOut} textRef={textRef} />
      {/* 3D Environment */}
      <Canvas
        className={"render md:opacity-100 " + (activated ? "opacity-100" : "opacity-0")}
        gl={{ antialias: true }}
        frameloop="demand"
        shadows
        dpr={[1, 2.5]}
        camera={{
          position: [0, 0.38, 1.14],
          rotation: [0, 0.01, 0],
          fov: 55,
          zoom: 1,
        }}
      >
        <ambientLight intensity={0.35} />
        <Suspense fallback={null}>{zamia && <PlantMedium />}</Suspense>
        <Suspense fallback={null}>{zamia && plants && <ZamiaBases withBase={withBase} />}</Suspense>
        <Suspense fallback={null}>
          <Stage
            controls={ref}
            preset={"rembrandt"}
            intensity={0.25}
            contactShadow={true}
            shadows={true}
            adjustCamera={false}
            environment={"warehouse"}
          >
            {selectModel()}
          </Stage>
        </Suspense>
        <ControlCamera withBase={withBase} />
      </Canvas>
      {/* Mobile Model Viewer */}
      {window.innerWidth <= 640 && <ModelViewer />}
      <BottomSpacer />
    </VisualizerWrapper>
  )
}

export default Customizer
