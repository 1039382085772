import React, { useRef, useEffect } from "react"
import { useGLTF } from "@react-three/drei"
import { useSnapshot } from "valtio"
import { setLoadingState, state } from "../../utils/state"
import { LightenDarkenColor } from "../../utils/helpers"

export default function Bombo({ hide, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF("/bombo.glb")
  const {
    color,
    modelLoading: { zamia, bases },
  } = useSnapshot(state)

  useEffect(() => {
    if (zamia && bases) {
      console.log("Preloading bombo")
      useGLTF.preload("/bombo.glb")
    }
  }, [zamia, bases])

  useEffect(() => {
    if (nodes) {
      console.log("Loaded Bombo")
      setLoadingState("bombo", true)
    }
  }, [nodes])

  if (hide) return null

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bombo.geometry}
        material={materials.Paint}
        material-color={color !== "stroke" ? (color === "#15110A" || color === "#005D6F" ? color : LightenDarkenColor(color, -60)) : "#ffffff"}
      />
    </group>
  )
}

useGLTF.preload("/bombo.glb")
