import React from "react"

function DragIcon({ ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <circle cx="15.625" cy="15.625" r="5.625" fill="#DB9A35"></circle>
      <path
        stroke="#DB9A35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M26.5 12.25l3.75 3.375L26.5 19M4.75 19L1 15.625l3.75-3.375M12.25 4.75L15.625 1 19 4.75M19 27.25L15.625 31l-3.375-3.75"
      ></path>
    </svg>
  )
}

export default DragIcon
