import React, { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import {
  baseTypeAvailable,
  getCurrentProductBases,
  getCurrentProductColors,
  getBaseColors,
  stageDisplayName,
  nextStage,
  lastStage,
  addCurrentProductToCart,
} from "../../utils/helpers"
import { ProductInfo } from "../../utils/ProductInfo"
import { setProxyState, state } from "../../utils/state"
import { useDrag } from "@use-gesture/react"

import stroke from "../../assets/stroke_icon.jpg"
import zamiaPrev from "../../assets/pots/zamia.svg"
import totemPrev from "../../assets/pots/totem.svg"
import bomboPrev from "../../assets/pots/bombo.svg"
import bombitoPrev from "../../assets/pots/bombito.svg"
import badge from "../../assets/badge.svg"
import cartIcon from "../../assets/cart.svg"
import { Colors } from "../../utils/colors"
import {
  BaseButton,
  BaseOption,
  OptionsBar,
  OptionsContainerMobile,
  PotOption,
  MobileRow,
  OptionsBarOuter,
  ColorOptionMobile,
  BaseContainer,
} from "./OptionPickerMobile.styles"
import SizeSlider from "./Steps/SizeSlider"
import { displayInfo } from "../../utils/displayInfo"
import ChevronIcon from "../Icons/ChevronIcon"
import ArrowIcon from "../Icons/ArrowIcon"
import { Row } from "../OptionPicker/OptionPicker.styles"

const OptionsPicker = ({ setWithBase, setShowLoading, started, setStarted, ...props }) => {
  const {
    selectedPot,
    color,
    size,
    selectedBase,
    modelLoading: { totem, bases, plants },
    currentStage,
    pickerHidden,
  } = useSnapshot(state)
  const [, setDirtyOptions] = useState({
    pot: false,
    color: false,
    size: false,
    base: false,
  })
  const [sliderPosition, setSliderPosition] = useState(66)
  const currentBases = getCurrentProductBases()

  const onBaseSelected = base => {
    if (!bases) {
      setShowLoading(true)
    }
    setProxyState("selectedBase", base)
    setProxyState("baseColor", ProductInfo[base].colors[0])
    setWithBase(true)
  }

  const onSizeSelected = size => {
    if (!baseTypeAvailable(size)) {
      setProxyState("selectedBase", "None")
      setWithBase(false)
    }

    setProxyState("size", size)
  }

  const onPotSelected = pot => {
    if (pot === "Totem" && !totem) {
      setShowLoading(true)
    }

    if (pot !== selectedPot) {
      setProxyState("selectedBase", "None")
      setProxyState("size", pot === "Totem" || pot === "Bombito" || pot === "Bombo" ? ProductInfo[pot].sizes[0].name : ProductInfo[pot].sizes[2].name)
      setProxyState("selectedPot", pot)
      setProxyState("color", pot === "Totem" || pot === "Bombito" || pot === "Bombo" ? ProductInfo[pot].colors[0] : ProductInfo[pot].colors[1])
      setDirtyOptions({
        pot: true,
        color: false,
        size: false,
        base: false,
      })
      // setWithBase(false)
    }
  }

  const handleSizeChange = ({ target: { value } }) => {
    if (value < 15 && size !== "mini") {
      onSizeSelected("mini")
      setSliderPosition(0)
    } else if (value > 15 && value < 45 && size !== "small") {
      onSizeSelected("small")
      setSliderPosition(33)
    } else if (value > 45 && value < 80 && size !== "med") {
      onSizeSelected("med")
      setSliderPosition(66)
    } else if (value >= 80 && size !== "large") {
      onSizeSelected("large")
      setSliderPosition(100)
    }
  }

  useEffect(() => (totem ? setShowLoading(false) : null), [totem])
  useEffect(() => (bases ? setShowLoading(false) : null), [bases])
  useEffect(() => (plants ? setShowLoading(false) : null), [plants])

  const bind = useDrag(
    state => {
      const {
        swipe, // [swipeX, swipeY] 0 if no swipe detected, -1 or 1 otherwise
        tap, // is the drag assimilated to a tap
        direction,
      } = state

      if (!tap && (swipe[1] === 1 || direction[1] === 1) && !pickerHidden) setProxyState("pickerHidden", true)
      if (!tap && (swipe[1] === -1 || direction[1] === -1) && pickerHidden) setProxyState("pickerHidden", false)
    },
    { axis: "y" }
  )

  return (
    <OptionsContainerMobile {...bind()} $stage={currentStage} $hidden={pickerHidden} className={started ? "revealed" : null} {...props}>
      {/* Navigation Bar */}
      {currentStage !== "confirm" && (
        <OptionsBarOuter className={started ? "revealed" : null}>
          <div
            className={"absolute top-3 left-0 right-0 m-auto w-8 h-1 rounded-full opacity-70 " + (pickerHidden ? "bg-fullWhite" : "bg-gray-mid")}
          ></div>
          <OptionsBar stage={currentStage}>
            <ArrowIcon
              id="back"
              onClick={lastStage}
              background={currentStage === "pot" || pickerHidden ? Colors.lightAccent.dark : Colors.highlight}
              style={{ opacity: currentStage === "pot" || pickerHidden ? 0.5 : 1, pointerEvents: currentStage === "pot" ? "none" : "auto" }}
            />
            <div className="flex items-center space-x-4">
              <h4 className={pickerHidden ? "text-fullWhite" : "text-darkEarth"}>{stageDisplayName()}</h4>
              {/* <ChevronIcon
                color={pickerHidden ? "#fff" : Colors.darkEarth}
                onClick={() => setProxyState("pickerHidden", !pickerHidden)}
                className={"transform " + (pickerHidden ? "rotate-180" : "")}
              /> */}
            </div>
            <ArrowIcon
              id="next"
              onClick={nextStage}
              background={pickerHidden ? Colors.lightAccent.dark : Colors.highlight}
              style={{ opacity: pickerHidden ? 0.5 : 1, pointerEvents: pickerHidden ? "none" : "auto" }}
            />
          </OptionsBar>
        </OptionsBarOuter>
      )}
      {/* Start Button */}
      <button
        id="start-mobile"
        className={started || currentStage ? "hidden" : null}
        onClick={() => {
          setProxyState("currentStage", "pot")
          setStarted(true)
        }}
      >
        Empieza
      </button>
      {/* Pot Selection */}
      {currentStage === "pot" ? (
        <MobileRow className="w-full flex-nowrap relative overflow-x-scroll">
          <div className="w-auto xs:w-full flex justify-start ml-28 mr-3 xs:ml-0 xs:mr-0">
            <PotOption $selected={selectedPot === "Zamia"} onClick={() => onPotSelected("Zamia")}>
              <img alt="pot" src={zamiaPrev} />
              <h6>Zamia</h6>
            </PotOption>
            <PotOption $selected={selectedPot === "Totem"} onClick={() => onPotSelected("Totem")}>
              <img alt="pot" src={totemPrev} />
              <h6>Totem</h6>
            </PotOption>
            <PotOption $selected={selectedPot === "Bombo"} onClick={() => onPotSelected("Bombo")}>
              <img alt="pot" src={bomboPrev} />
              <h6>Bombo</h6>
            </PotOption>
            <PotOption $selected={selectedPot === "Bombito"} onClick={() => onPotSelected("Bombito")}>
              <img alt="pot" src={bombitoPrev} />
              <h6>Bombito</h6>
            </PotOption>
          </div>
        </MobileRow>
      ) : null}
      {/* Color Selection */}
      {currentStage === "color" ? (
        <div className="w-full flex justify-center flex-wrap space-x-5">
          {currentStage === "color" &&
            getCurrentProductColors().map(colorOption => (
              <ColorOptionMobile
                className="w-8 h-8 relative rounded-full border border-white mb-6"
                key={colorOption}
                onClick={() => setProxyState("color", colorOption)}
                $selected={colorOption === color}
              >
                {colorOption === "stroke" ? (
                  <img alt="stroke color" src={stroke} className="rounded-full" />
                ) : (
                  <div
                    className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover object-center h-full w-full rounded-full"
                    style={{ backgroundColor: colorOption }}
                  />
                )}
              </ColorOptionMobile>
            ))}
        </div>
      ) : null}
      {/* Size Selection */}
      {currentStage === "size" ? (
        <div className="w-full flex flex-wrap px-10">
          <SizeSlider value={sliderPosition} onChange={e => handleSizeChange(e)} />
        </div>
      ) : null}

      {/* Base Selection */}
      {currentStage === "base" ? (
        <>
          <BaseContainer className="w-full relative flex items-center justify-center">
            <div className="overflow-x-scroll flex flex-nowrap h-14 space-x-5">
              {currentStage === "base" &&
                currentBases.map(baseInfo => {
                  return (
                    <BaseOption key={baseInfo.name} className={selectedBase === baseInfo.name ? "selected" : null}>
                      <BaseButton key={baseInfo.name} onClick={() => onBaseSelected(baseInfo.name)} className="px-4">
                        <p className="small">{baseInfo.displayName}</p>
                      </BaseButton>
                    </BaseOption>
                  )
                })}
            </div>
          </BaseContainer>
          <BaseContainer className="w-full relative flex items-center justify-center">
            <div className="overflow-x-scroll flex flex-nowrap h-14 space-x-5 p-1">
              {currentStage === "base" &&
                getBaseColors(selectedBase).map(colorOption => {
                  return (
                    <div>
                      <ColorOptionMobile
                        className="w-8 h-8 relative rounded-full border border-white mb-6"
                        key={colorOption}
                        onClick={() => setProxyState("baseColor", colorOption)}
                        $selected={colorOption === color}
                      >
                        {colorOption === "stroke" ? (
                          <img alt="stroke color" src={stroke} className="rounded-full" />
                        ) : (
                          <div
                            className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover object-center h-full w-full rounded-full"
                            style={{ backgroundColor: colorOption }}
                          />
                        )}
                      </ColorOptionMobile>
                    </div>
                  )
                })}
            </div>
          </BaseContainer>
        </>
      ) : null}
      {/* Confirm Item */}
      {currentStage === "confirm" && (
        <>
          <div
            className={"absolute top-3 left-0 right-0 m-auto w-8 h-1 rounded-full opacity-70 " + (pickerHidden ? "bg-fullWhite" : "bg-gray-mid")}
          ></div>
          <div className="w-full flex flex-col mt-7 space-y-6 px-3">
            <div className="w-full flex justify-between font-black">
              <h5 className={pickerHidden ? "text-fullWhite" : "text-darkEarth"}>Maceta {selectedPot}</h5>
              <h5 className={pickerHidden ? "text-fullWhite" : "text-highlight"}>${displayInfo[selectedPot].price[size]}</h5>
            </div>
            <div className={"rounded-lg border border-gray-mid w-full h-10 flex " + (pickerHidden ? "opacity-0" : "opacity-100")}>
              <div className="h-full flex-grow border-r border-gray-mid py-2 px-4 flex flex-row items-center justify-between">
                <p className="small font-bold">C</p>
                <p className="small">{displayInfo.colorDictionary[color]}</p>
              </div>
              <div className="h-full flex-grow border-r border-gray-mid py-2 px-4 flex flex-row items-center justify-between">
                <p className="small font-bold">T</p>
                <p className="small">{displayInfo.sizeDictionary[size].name}</p>
              </div>
              <div className="h-full flex-grow py-2 px-4 flex flex-row items-center justify-between">
                <p className="small font-bold">B</p>
                <p className="small">{displayInfo.bases[selectedBase].name}</p>
              </div>
            </div>
            <div className="w-full flex">
              <img src={badge} alt="Made in nicaragua" className="h-14 opacity-50 mr-6" />
              <p className="small text-gray-dark text-left w-full" style={{ maxWidth: 225 }}>
                Hand-Made for you at our factory, this vase is 100% custom and loved before it arrives at your place.
              </p>
            </div>
            <div className="w-full flex items-center justify-center space-x-5">
              <ArrowIcon background={Colors.highlight} className="transform rotate-180 bg-highlight rounded-full" onClick={lastStage} />
              <img alt="cart" src={cartIcon} className="w-11 bg-highlight rounded-full" onClick={() => addCurrentProductToCart()} />
            </div>
          </div>
        </>
      )}
    </OptionsContainerMobile>
  )
}

export default OptionsPicker
