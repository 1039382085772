import React, { useState } from "react"
import styled from "styled-components"
import { useSnapshot } from "valtio"

import close from "../../assets/close.svg"
import remove from "../../assets/remove.svg"
import logo from "../../assets/logo-white.svg"
import { displayInfo } from "../../utils/displayInfo"
import { calculateTotalPrice, removeFromCartByIndex } from "../../utils/helpers"
import { setProxyState, state } from "../../utils/state"
import TextInput from "../../components/TextInput"

const CartContainer = styled.div`
  max-width: 559px;
  transform: translateX(${({ open }) => (open ? 0 : 100)}%);
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  pointer-events: ${({ hidden }) => (hidden ? "none" : "auto")};

  transition: transform 0.6s, opacity 0.6s;

  & .item-title {
    font-size: 26px;
    line-height: 29px;
  }

  & .thanks {
    font-size: 48px;
    line-height: 53px;
  }

  & .tag {
    font-weight: 200;
    font-size: 28px;
    line-height: 36px;
    max-width: 404px;
    width: 100%;
  }

  @media (max-width: 640px) {
    & .thanks {
      font-size: 36px;
      line-height: 42 px;
    }
  }
`

const CartItem = ({ item, erase }) => {
  return (
    <div className="w-full flex flex-col text-pink space-y-8">
      <div className="w-full flex items-center justify-between font-bold">
        <div className="flex items-center space-x-4">
          <h5 className="item-title">Maceta {item.pot}</h5>
          <h6>x {item.quantity}</h6>
        </div>
        <div className="flex items-center space-x-4">
          <h5>$ {item.price}</h5>
          <img alt="remove" src={remove} onClick={erase} className="cursor-pointer" />
        </div>
      </div>
      <div className="rounded-lg border border-white w-full h-10 sm:h-14 flex">
        <div className="h-full flex-grow border-r border-white py-2 px-4 sm:py-4 sm:px-6 flex flex-row items-center justify-between">
          <h6 className="font-bold text-xs sm:text-base">C</h6>
          <h6 className="text-xs sm:text-base">{displayInfo.colorDictionary[item.color]}</h6>
        </div>
        <div className="h-full flex-grow border-r border-white py-2 px-4 sm:py-4 sm:px-6 flex flex-row items-center justify-between">
          <h6 className="font-bold text-xs sm:text-base">T</h6>
          <h6 className="text-xs sm:text-base">{displayInfo.sizeDictionary[item.size].name}</h6>
        </div>
        <div className="h-full flex-grow py-2 px-4 sm:py-4 sm:px-6 flex flex-row items-center justify-between">
          <h6 className="font-bold text-xs sm:text-base">B</h6>
          <h6 className="text-xs sm:text-base">{displayInfo.bases[item.base].name}</h6>
        </div>
      </div>
    </div>
  )
}

const CartOverlay = () => {
  const { cartOpen, cart } = useSnapshot(state)
  const [formOpen, setFormOpen] = useState(false)
  const currentCart = JSON.parse(cart)

  return (
    <>
      <div
        className={
          "absolute w-screen h-screen bg-pink transition-colors pointer-events-none " +
          (cartOpen ? "pointer-events-auto bg-opacity-80" : "pointer-events-none bg-opacity-0")
        }
        style={{ zIndex: 11 }}
      ></div>
      <div
        className={"absolute right-0 bottom-0 h-full bg-darkEarth transition-all duration-700 " + (cartOpen && formOpen ? "w-full sm:w-2/3" : "w-0")}
        style={{ zIndex: 11 }}
      />
      <CartContainer
        className="absolute z-20 overflow-scroll right-0 flex flex-col w-full bg-darkEarth h-full p-8 sm:p-14 space-y-20"
        open={cartOpen}
        hidden={formOpen}
      >
        <div className="flex items-center justify-between text-pink relative z-50">
          <p>Tu carrito</p>
          <div
            className="flex items-center space-x-8 cursor-pointer"
            onClick={() => {
              setProxyState("cartOpen", false)
              setFormOpen(false)
            }}
          >
            <p className="hidden sm:block">Cerrar</p>
            <img alt="close" src={close} />
          </div>
        </div>
        <div className="flex flex-col w-full space-y-16">
          <div className="flex flex-col w-full space-y-16 relative">
            {currentCart.length > 0 &&
              currentCart.map((item, i) => <CartItem key={cart.pot + " " + i} item={item} erase={() => removeFromCartByIndex(i)} />)}
            {currentCart.length === 0 && <p className="text-pink">No hay macetas en tu carrito</p>}
          </div>
          <div className="flex items-center justify-between text-pink font-bold border-t border-pink pt-8">
            <h5 className="item-title">Total</h5>
            <h5 className="item-title">$ {calculateTotalPrice()}</h5>
          </div>
          <button
            className={
              "bg-highlight text-fullWhite hover:bg-pink hover:text-darkEarth transition-all duration-500 font-bold ease-cassu " +
              (currentCart.length === 0 ? "pointer-events-none opacity-60" : "")
            }
            onClick={() => setFormOpen(true)}
          >
            Reservar
          </button>
          <div className="w-full flex flex-col justify-center items-center pt-2 space-y-4">
            <p className="eiko uppercase text-pink tag">Traigamos la naturaleza a tu hogar.</p>
            <img alt="logo" src={logo} />
          </div>
        </div>
      </CartContainer>
      <CartContainer
        className="absolute z-20 overflow-scroll right-0 flex flex-col w-full h-full p-8 sm:p-14 space-y-8 sm:space-y-20"
        open={cartOpen}
        hidden={!formOpen}
      >
        <div className="flex items-center justify-between text-pink relative z-50">
          <p></p>
          <div
            className="flex items-center space-x-8 cursor-pointer"
            onClick={() => {
              setProxyState("cartOpen", false)
              setFormOpen(false)
            }}
          >
            <p className="hidden sm:block">Cerrar</p>
            <img alt="close" src={close} />
          </div>
        </div>
        <div className="flex flex-col w-full space-y-12 text-left text-pink">
          <h3 className="thanks text-left uppercase text-highlight font-bold">
            <span className="text-pink eiko font-light"> Thank you for</span> your interest!
          </h3>
          <p className="text-pink text-left">
            Thank you for using our Tech-Demo. If you’re interested in any of the pots - submit your E-Mail and Name to get in touch with Cassú.
          </p>
          <div className="flex flex-col space-y-5">
            <TextInput label="Nombre" />
            <TextInput label="E-mail" />
            <TextInput label="Notas" />
          </div>
          <button
            className="bg-highlight text-fullWhite hover:bg-pink hover:text-darkEarth transition-colors ease-cassu duration-500 font-bold"
            onClick={() => setFormOpen(true)}
          >
            Enviar
          </button>
          <div className="w-full flex flex-col justify-center items-center pt-2 space-y-4">
            <img alt="logo" src={logo} />
          </div>
        </div>
      </CartContainer>
    </>
  )
}

export default CartOverlay
