import React from "react"

function ArrowIcon({ color, background, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="none" viewBox="0 0 45 45" {...props}>
      <g filter="url(#filter0_b)">
        <rect width="45" height="45" x="45" y="45" fill={background || "#DB9A35"} rx="22.5" transform="rotate(-180 45 45)"></rect>
      </g>
      <path
        fill={color || "#fff"}
        fillRule="evenodd"
        d="M23.347 27.258l.058.059c.252.225.65.243.924.052l.066-.052 4.9-4.375.029-.027.039-.043.05-.07.037-.07.025-.066.02-.092.005-.074-.002-.047-.012-.078-.02-.07-.032-.07-.036-.06-.052-.066-.051-.05-4.9-4.376a.76.76 0 00-.99 0 .577.577 0 00-.058.825l.058.059 3.704 3.308H16.2c-.387 0-.7.28-.7.625s.313.625.7.625h10.91l-3.705 3.308a.577.577 0 00-.058.825l.058.059-.058-.059z"
        clipRule="evenodd"
      ></path>
      <defs>
        <filter id="filter0_b" width="81" height="81" x="-18" y="-18" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur in="BackgroundImage" stdDeviation="9"></feGaussianBlur>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"></feComposite>
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  )
}

export default ArrowIcon
