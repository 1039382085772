import { proxy } from "valtio"
import { ProductInfo } from "./ProductInfo"

const ARFiles = [
  "zamia_005D6F_bench_plant",
  "zamia_005D6F_bench",
  "zamia_005D6F_geo",
  "zamia_15110A_bench_plant",
  "zamia_15110A_bench",
  "zamia_15110A_none_plant",
]

export const state = proxy({
  currentStage: null,
  selectedPot: "Zamia",
  selectedBase: "None",
  color: ProductInfo["Zamia"].colors[1],
  baseColor: ProductInfo["GEO"].colors[0],
  quantity: 1,
  size: "med",
  withPlant: true,
  fileName: "zamia_005D6F_none",
  arAvailable: false,
  viewer: null,
  cart: "[]",
  cartOpen: false,
  pickerHidden: false,
  modelLoading: {
    zamia: false,
    bases: false,
    totem: false,
    plants: false,
    bombito: false,
    bombo: false,
  },
})

export const setProxyState = (item, value) => {
  state[item] = value
}

export const setLoadingState = (item, value) => {
  state.modelLoading[item] = value
}

export const setFileNameAndCheck = value => {
  state.fileName = value

  state.arAvailable = ARFiles.filter(file => file === value).length === 1

  console.log(value, ARFiles.filter(file => file === value).length === 1)
}
