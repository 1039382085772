import styled from "styled-components/macro"
import { Colors } from "../../utils/colors"
import { Row } from "../OptionPicker/OptionPicker.styles"

export const VisualizerWrapper = styled.div`
  position: absolute;
  max-width: 1557px;
  width: 80vw;
  height: 87vh;
  height: calc(var(--variable-vh, 1vh) * 87);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;

  /* & * {
    transition: opacity 2s cubic-bezier(0.425, 0.005, 0, 1), transform 2s cubic-bezier(0.425, 0.005, 0, 1);
  } */

  & .hidden {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0s;
    transform: translateY(2vw);
  }

  & .reveal {
    opacity: 1;
    transition-delay: 1s;
    transform: translateY(0vw);
  }

  & .render {
    position: fixed !important;
    left: 0;
    top: 0;
    z-index: ${({ cartOpen }) => (cartOpen ? 11 : 0)};
    transition: opacity 0.6s 2s ease-in;
  }

  /* & .chars {
    transition: opacity 2s cubic-bezier(0.425, 0.005, 0, 1), transform 2s cubic-bezier(0.425, 0.005, 0, 1);
  }

  & .headline-hidden .chars {
    opacity: 0;
    transition-duration: 1.5s;
    transform: translate3d(95px, 0px, -285px) rotateY(90deg);
  }

  & .headline-reveal .chars {
    opacity: 1;
    transition-duration: 1.5s;
    transform: translate3d(0px, 0px, 0px) rotateY(0deg);
    min-width: 1vw;
  } */

  /* ${({ characters }) => {
    let templateLiteral = ``
    characters.forEach((n, i) => {
      templateLiteral += `
          & .chars:nth-child(${i}) {
            transition-delay: ${0.05 * i}s;
          }
        `
    })
    return templateLiteral
  }}

  & .chars {
    transition-delay: 0s;
  } */

  @media (max-width: 1200px) {
    width: 100vw;
    border-radius: 10px;
    height: 83%;

    transition: transform 1.5s 0.3s cubic-bezier(0.425, 0.005, 0, 1);

    &.mobile-active {
      transform: translateY(-21%);
    }

    &.hidden {
      transform: translateY(-6%);
    }
  }
`

export const BottomSpacer = styled.div`
  width: 100%;
  height: 55px;
`

export const ColorPreview = styled.div`
  position: absolute;
  overflow: hidden;
  left: -52px;
  top: -85px;
  width: 104px;
  height: 104px;
  background: ${({ color }) => color};
  border-radius: 500px;

  & img {
  }

  &.hidden {
    pointer-events: none;
    transition: background 0.5s 0s cubic-bezier(0.425, 0.005, 0, 1), opacity 0.2s 0s cubic-bezier(0.425, 0.005, 0, 1) !important;
  }

  &.reveal {
    transition: background 0.5s 0s cubic-bezier(0.425, 0.005, 0, 1), opacity 0.8s 1s cubic-bezier(0.425, 0.005, 0, 1) !important;
  }
`

export const MainHeadline = styled.div`
  position: absolute;
  top: 86px;
  left: -75px;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 415px;
  pointer-events: none;

  & h1 {
    margin: 0;
    margin-bottom: 70px;
  }

  & p {
    max-width: 243px;
    color: ${Colors.darkEarth};
    margin: 0;
  }

  & span {
    color: ${Colors.highlight};
  }

  @media (max-height: 800px), (max-width: 1280px) {
    & h1 {
      font-size: 10.2vh;
      line-height: 10.8vh;
    }
  }

  @media (max-width: 1440px) {
    top: 52px;

    & h1 {
      margin-bottom: 35px;
    }
  }

  @media (max-width: 1200px) {
    top: 0px;
    width: 85%;
    left: 30px;

    & h1 {
      font-size: 56px;
      line-height: 56px;
    }

    & p {
      display: none;
    }
  }
`

export const MainDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 85px;
  text-align: left;
  max-width: 371px;
  width: 371px;
  margin: auto;
  color: ${Colors.darkEarth};

  & h2 {
    text-align: left;
  }

  & p {
    max-width: 247px;
    z-index: 2;
    position: relative;
  }

  & .blend {
    mix-blend-mode: exclusion;
    color: white;
  }

  & .item {
    position: absolute;
    opacity: 0;
    transform: translateY(2vw);

    /* transition: transform 0.8s 0s cubic-bezier(0.425, 0.005, 0, 1), opacity 0.8s 0s cubic-bezier(0.425, 0.005, 0, 1); */
  }

  /* & .item.reveal {
    opacity: 1;
    transform: translateY(0vw);
  } */

  & .spaced {
    margin: 37px 0px;
  }

  & .handmade {
    left: 80px;
    top: 32px;
  }

  & .confirm {
    /* right: 20px; */
  }

  & .headline-bottom {
    margin-bottom: 26px;
  }

  & .breakdown {
    & ${Row} {
      justify-content: flex-start;
      position: relative;
    }

    & h6 {
      margin: 10px 0px;
    }

    & .selection {
      font-weight: bold;
      position: absolute;
      left: 100px;
    }
  }

  @media (max-height: 800px) {
    & .handmade {
      top: 26px !important;
      left: 81px;

      & img {
        height: 31vh;
      }
    }

    & .spaced {
      margin: 15px 0px;
    }
  }

  @media (max-width: 1680px) {
    right: 18px;
  }

  @media (max-width: 1440px) {
    right: -52px;

    & .confirm {
      left: -34px;
    }
    & .long {
      top: 55px;
    }
  }

  @media (max-width: 1200px) {
    display: none;
  }
`

export const Badge = styled.img`
  position: absolute;
  left: -89px;
  top: 200px;

  @media (max-width: 1200px) {
    display: none;
  }
`
