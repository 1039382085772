import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import DragIcon from "./Icons/DragIcon"

const Tip = styled.div`
  transform: translateY(25px);
  opacity: 0;

  transition: transform 1.5s 1.5s cubic-bezier(0.425, 0.005, 0, 1), opacity 1.5s 1.5s cubic-bezier(0.425, 0.005, 0, 1);
  ${({ $delay }) =>
    $delay &&
    css`
      transition-delay: ${({ $delay }) => $delay}s;
    `}

  &.show {
    transform: translateY(0);
    opacity: 1;
  }
`

const Instructions = ({ show, delay }) => {
  const [hide, setHide] = useState(false)
  useEffect(() => {
    console.log(show)
    if (show) setTimeout(() => setHide(true), 2300)
  }, [show])
  return (
    <Tip
      className={
        "absolute z-10 top-0 left-0 right-0 bottom-0 m-auto w-72 h-20 rounded-full flex items-center justify-center px-6 space-x-3 pointer-events-none bg-white " +
        (show && !hide ? "show" : "")
      }
      $delay={delay}
      style={{ backdropFilter: "blur(18px)" }}
    >
      <DragIcon className="w-16" />
      <p className="text-left" style={{ fontSize: 15 }}>
        <strong>Click & Drag</strong> to view the vase from multiple angles.
      </p>
    </Tip>
  )
}

export default Instructions
