import React from "react"
import Slider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import { Colors } from "../../../utils/colors"

const marks = [
  {
    value: 0,
    label: "Mini",
  },
  {
    value: 30,
    label: "P",
  },
  {
    value: 65,
    label: "M",
  },
  {
    value: 100,
    label: "L",
  },
]

const iOSBoxShadow = "0px 7px 11px rgba(57, 44, 24, 0.2)"

const SizeRange = styled(Slider)(({ theme }) => ({
  color: Colors.darkEarth,
  height: 4,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 30,
    width: 30,
    backgroundColor: Colors.highlight,
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 7px 11px rgba(57, 44, 24, 0.2)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#D2C3AA",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#D2C3AA",
    height: 14,
    width: 1.5,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}))

const SizeSlider = ({ value, onChange }) => {
  return (
    <div className="w-full">
      <SizeRange aria-label="ios slider" defaultValue={value} marks={marks} onChange={onChange} />
    </div>
  )
}

export default SizeSlider
