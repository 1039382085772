import React, { useEffect, useRef } from "react"
import { useSnapshot } from "valtio"
import { state } from "../../../utils/state"
import { Colors } from "../../../utils/colors"
import { capitalize, calculatePrice } from "../../../utils/helpers"
import { Badge, ColorPreview, MainDescription, MainHeadline } from "../Customizer.styles"

import stroke from "../../../assets/stroke_icon.jpg"
import ar from "../../../assets/open-AR.jpeg"
import badge from "../../../assets/badge.svg"
import { Row } from "../../OptionPicker/OptionPicker.styles"
import { displayInfo } from "../../../utils/displayInfo"
import QuantityPicker from "../../QuantityPicker"
import { CloseButton } from "../../OptionPickerMobile/OptionPickerMobile.styles"
import gsap from "gsap"
import Instructions from "../../Instructions"

const CustomizerUI = ({ textRef, activated, splitTextOut, started }) => {
  const badgeRef = useRef()
  const textsRef = useRef([null, null])
  const itemRefs = useRef([null, null, null, null])
  const { selectedPot, currentStage, color, size, selectedBase, quantity, arAvailable } = useSnapshot(state)
  const { current: timeline } = useRef(new gsap.timeline({ paused: true }))

  const onARStarted = () => {
    const ev = new Event("ar-click")

    window.dispatchEvent(ev)
  }

  useEffect(() => {
    gsap.set([...itemRefs.current, badgeRef.current], { y: "2vw", autoAlpha: 0 })
  }, [])

  useEffect(() => {
    if (splitTextOut) {
      gsap.set(textsRef.current, { autoAlpha: 0 })
      gsap.set(splitTextOut.chars, { x: 95, z: -285, rotateY: 90, autoAlpha: 0 })
    }

    if (activated) {
      timeline
        .to(splitTextOut.chars, { stagger: 0.05, x: 0, z: 0, rotateY: 0, autoAlpha: 1, duration: 2, ease: "power4.out" }, "start")
        .to(textsRef.current, { autoAlpha: 1, ease: "power4.out", duration: 1 }, "start+=1")
        .play()
    }
  }, [splitTextOut, activated])

  useEffect(() => {
    if (currentStage) {
      gsap.to(badgeRef.current, { autoAlpha: 1, y: "0vw", delay: 0.8 })
      gsap.to([...textsRef.current, textRef.current], { autoAlpha: 0, y: "2vw" })
    }

    if (currentStage === "pot") {
      transitionStage(0)
    }
    if (currentStage === "color") {
      transitionStage(1)
    }
    if (currentStage === "size") {
      transitionStage(2)
    }
    if (currentStage === "base") {
      transitionStage(null)
    }
    if (currentStage === "confirm") {
      transitionStage(3)
    }
  }, [currentStage])

  const transitionStage = (showItem, immediate) => {
    gsap.to(itemRefs.current, { y: "2vw", autoAlpha: 0 })

    if (showItem !== null && showItem !== undefined) gsap.to(itemRefs.current[showItem], { y: "0vw", autoAlpha: 1, delay: immediate ? 0 : 0.8 })
  }

  return (
    <>
      {/* Left Side */}
      <Badge ref={badgeRef} alt="badge" src={badge} />
      <MainHeadline>
        <h1 ref={textRef}>
          Macetas como te <span>gustan</span>
        </h1>
        <p ref={ref => (textsRef.current[0] = ref)}>Todas nuestras macetas se han hecho con las manos y el corazón</p>
      </MainHeadline>
      {/* Right Side */}
      <MainDescription style={{ zIndex: currentStage === "confirm" || !currentStage ? 10 : 0 }}>
        <h3 ref={ref => (textsRef.current[1] = ref)}>Espacios interiores llenos de vida y diseño contemporáneo.</h3>
        <div ref={ref => (itemRefs.current[0] = ref)} className={"item long "}>
          <h2>La Maceta</h2>
          <h2 className={"mb-7"} style={{ color: Colors.lightAccent.dark }}>
            {capitalize(selectedPot)}
          </h2>
          <p>{displayInfo[selectedPot].description}</p>
        </div>
        <div ref={ref => (itemRefs.current[1] = ref)} className={"item "}>
          <h2
            style={{
              position: "relative",
              zIndex: 2,
            }}
          >
            El color
          </h2>
          <h2
            style={{
              color: Colors.lightAccent.dark,
            }}
          >
            {displayInfo.colorDictionary[color]}
          </h2>
          <ColorPreview color={color}>{color === "stroke" && <img alt="texture-preview" src={stroke} />}</ColorPreview>
        </div>
        <div ref={ref => (itemRefs.current[2] = ref)} className={"item "}>
          <h2>{displayInfo.sizeDictionary[size].name}</h2>
          <h2 style={{ color: Colors.lightAccent.dark }}>{displayInfo.sizeDictionary[size].dimensions}</h2>
        </div>
        <div ref={ref => (itemRefs.current[3] = ref)} className={"item confirm "}>
          <h2>Tu maceta esta lista</h2>
          <h2 className={"headline-bottom"} style={{ color: Colors.lightAccent.dark }}>
            ${calculatePrice()}
          </h2>
          <div className={"breakdown"}>
            <Row>
              <h6>Maceta</h6>
              <h6 className="selection">{capitalize(selectedPot)}</h6>
            </Row>
            <Row>
              <h6>Color</h6>
              <h6 className="selection">{displayInfo.colorDictionary[color]}</h6>
            </Row>
            <Row>
              <h6>Tamaño</h6>
              <h6 className="selection">
                {displayInfo.sizeDictionary[size].name} - {displayInfo.sizeDictionary[size].dimensions}
              </h6>
            </Row>
            <Row>
              <h6>Base</h6>
              <h6 className="selection">{displayInfo.bases[selectedBase].name}</h6>
            </Row>
            <Row>
              <h6>Cantidad</h6>
              <QuantityPicker quantity={quantity} className="selection" />
            </Row>
          </div>
        </div>
      </MainDescription>
      {/* AR Button */}
      <CloseButton
        className={"absolute right-4 top-36 z-10 flex sm:hidden " + (arAvailable && currentStage ? "opacity-100" : "opacity-0 pointer-events-none")}
        onClick={() => onARStarted()}
      >
        <img alt="open augmented reality" src={ar} />
      </CloseButton>
      {window.innerWidth > 640 && <Instructions show={activated} />}
    </>
  )
}

export default CustomizerUI
