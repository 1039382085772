import React, { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import {
  baseTypeAvailable,
  getCurrentProductBases,
  getCurrentProductColors,
  getCurrentSizes,
  getBaseColors,
  // calculatePrice,
  onAddToCart,
} from "../../utils/helpers"
import { ProductInfo } from "../../utils/ProductInfo"
import { setProxyState, state } from "../../utils/state"

import stroke from "../../assets/stroke_icon.jpg"
import zamiaPrev from "../../assets/pots/zamia.svg"
import totemPrev from "../../assets/pots/totem.svg"
import bomboPrev from "../../assets/pots/bombo.svg"
import bombitoPrev from "../../assets/pots/bombito.svg"
import check from "../../assets/check.svg"
import cartIcon from "../../assets/cart.svg"
import back from "../../assets/back.svg"
import pointarrow from "../../assets/pointarrow.svg"
// import plant from "../../assets/002-leaf.svg";
// import close from "../../assets/001-close.svg";
// import ar from "../../assets/open-AR.jpeg";
import { Colors } from "../../utils/colors"
// import { CartItem } from "../../utils/CartItem";
import {
  AddToCartButton,
  BaseButton,
  BaseColors,
  BaseOption,
  Button,
  MovingBackground,
  OptionsBar,
  OptionsButton,
  OptionsContainer,
  PotOption,
  Row,
  SelectionIndicator,
  StartPrompt,
} from "./OptionPicker.styles"

export const Option = ({ selectedCondition, indicatorCondition, action, text, size, noIndicator, disabled, forceBackground }) => {
  return (
    <OptionsButton
      forceBackground={forceBackground}
      disabled={disabled}
      dirty={indicatorCondition}
      size={size}
      className={disabled ? "disabled" : selectedCondition ? "selected" : ""}
      onClick={() => (!disabled ? action() : null)}
    >
      <SelectionIndicator size={size} bg={noIndicator ? null : indicatorCondition} />
      <h4>{text}</h4>
      <img alt="check" src={check} />
    </OptionsButton>
  )
}

const OptionsPicker = ({ setWithBase, setShowLoading, ...props }) => {
  const {
    selectedPot,
    color,
    size,
    selectedBase,
    modelLoading: { totem, bases, plants, bombito, bombo },
    currentStage,
    cartOpen,
  } = useSnapshot(state)
  const [hovered, setHovered] = useState(Colors.darkEarth)
  const [started, setStarted] = useState(false)
  const [dirtyOptions, setDirtyOptions] = useState({
    pot: false,
    color: false,
    size: false,
    base: false,
  })
  const currentBases = getCurrentProductBases()
  const sizes = getCurrentSizes()

  const onOptionSelected = option => {
    let dirty = dirtyOptions
    setProxyState("currentStage", option)

    if (dirty[option] === false) {
      dirty[option] = true
      setDirtyOptions(dirty)
    }

    if (!started) setStarted(true)
  }

  const onBaseSelected = base => {
    if (!bases) {
      setShowLoading(true)
    }
    setProxyState("selectedBase", base)
    setProxyState("baseColor", ProductInfo[base].colors[0])
    setWithBase(true)
  }

  const onSizeSelected = size => {
    if (!baseTypeAvailable(size)) {
      setProxyState("selectedBase", "None")
      setWithBase(false)
    }

    setProxyState("size", size)
  }

  const onPotSelected = pot => {
    if ((pot === "Totem" && !totem) || (pot === "Bombito" && !bombito) || (pot === "Bombo" && !bombo)) {
      setShowLoading(true)
    }

    if (pot !== selectedPot) {
      setProxyState("selectedBase", "None")
      setProxyState("size", pot === "Totem" || pot === "Bombito" || pot === "Bombo" ? ProductInfo[pot].sizes[0].name : ProductInfo[pot].sizes[2].name)
      setProxyState("selectedPot", pot)
      setProxyState("color", pot === "Totem" || pot === "Bombito" || pot === "Bombo" ? ProductInfo[pot].colors[0] : ProductInfo[pot].colors[1])
      setDirtyOptions({
        pot: true,
        color: false,
        size: false,
        base: false,
      })
      // setWithBase(false)
    }
  }

  // const onARStarted = () => {
  //   const ev = new Event("ar-click");

  //   window.dispatchEvent(ev);
  // };

  useEffect(() => (bombo ? setShowLoading(false) : null), [bombo])
  useEffect(() => (bombito ? setShowLoading(false) : null), [bombito])
  useEffect(() => (totem ? setShowLoading(false) : null), [totem])
  useEffect(() => (bases ? setShowLoading(false) : null), [bases])
  useEffect(() => (plants ? setShowLoading(false) : null), [plants])

  return (
    <OptionsContainer {...props}>
      {/* {
          currentStage ?
          <Row>
            <CloseButton onClick={() => setProxyState('currentStage', null)}>
              <img alt="close" src={close} className="close" />
            </CloseButton>
            <CloseButton onClick={() => {
              if (!plants) setShowLoading(true)
              setProxyState('withPlant', !withPlant)
            }}>
              <img alt="leaf" src={plant} />
            </CloseButton>
            {arAvailable && <CloseButton onClick={() => onARStarted()}>
                <img alt="ar" src={ar} className="ar" />
            </CloseButton>}
          </Row>
          :
          null
      } */}
      {currentStage === "pot" && !cartOpen ? (
        <Row>
          <PotOption onClick={() => onPotSelected("Zamia")}>
            <img alt="pot" src={zamiaPrev} />
            <h6>Zamia</h6>
          </PotOption>
          <PotOption onClick={() => onPotSelected("Totem")}>
            <img alt="pot" src={totemPrev} />
            <h6>Totem</h6>
          </PotOption>
          <PotOption onClick={() => onPotSelected("Bombo")}>
            <img alt="pot" src={bomboPrev} />
            <h6>Bombo</h6>
          </PotOption>
          <PotOption onClick={() => onPotSelected("Bombito")}>
            <img alt="pot" src={bombitoPrev} />
            <h6>Bombito</h6>
          </PotOption>
        </Row>
      ) : null}
      <Row
        style={{
          justifyContent: currentStage === "color" ? "flex-start" : "center",
        }}
      >
        {currentStage === "color" && (
          <div className="scrollable" onMouseLeave={() => setHovered(Colors.darkEarth)}>
            {getCurrentProductColors().map(colorOption => (
              <Button
                key={colorOption}
                bg={colorOption}
                image={colorOption === "stroke" ? stroke : null}
                onMouseEnter={() => setHovered(colorOption === "stroke" ? Colors.darkEarth : colorOption)}
                onClick={() => setProxyState("color", colorOption)}
              />
            ))}
          </div>
        )}
        {currentStage === "size" &&
          sizes.map(sizeInfo => (
            <Button
              bg={sizeInfo.name === "large" ? Colors.pink : Colors.darkEarth}
              size={sizeInfo.name}
              key={sizeInfo.name}
              onClick={() => onSizeSelected(sizeInfo.name)}
            />
          ))}
        {currentStage === "base" &&
          currentBases.map(baseInfo => {
            return (
              <BaseOption
                key={baseInfo.name}
                width={getBaseColors(baseInfo.name).length ? getBaseColors(baseInfo.name).length * 69 : 0}
                className={selectedBase === baseInfo.name ? "selected" : null}
              >
                <BaseButton key={baseInfo.name} onClick={() => onBaseSelected(baseInfo.name)}>
                  <h6>{baseInfo.displayName}</h6>
                </BaseButton>
                <BaseColors>
                  {getBaseColors(baseInfo.name).map((color, i) =>
                    i < 6 ? <Button key={color} bg={color} style={{ color: "white" }} onClick={() => setProxyState("baseColor", color)} /> : null
                  )}
                </BaseColors>
              </BaseOption>
            )
          })}
      </Row>
      <OptionsBar stage={currentStage}>
        <Option
          noIndicator
          text="Maceta"
          selectedCondition={currentStage === "pot"}
          indicatorCondition={dirtyOptions.pot ? true : null}
          action={() => onOptionSelected("pot")}
        />
        <Option
          text="Colores"
          selectedCondition={currentStage === "color"}
          indicatorCondition={currentStage !== "color" && dirtyOptions.color ? color : null}
          action={() => onOptionSelected("color")}
        />
        <Option
          text="Tamaño"
          selectedCondition={currentStage === "size"}
          indicatorCondition={dirtyOptions.size ? Colors.darkEarth : null}
          disabled={sizes && sizes.length <= 1}
          action={() => onOptionSelected("size")}
          size={size}
        />
        <Option
          noIndicator
          text="Base"
          selectedCondition={currentStage === "base"}
          indicatorCondition={dirtyOptions.base ? true : null}
          disabled={currentBases && currentBases.length <= 1}
          action={() => onOptionSelected("base")}
          size={size}
        />
        <MovingBackground bg={currentStage === "color" ? hovered : null} stage={currentStage} />
        <AddToCartButton
          className={
            "bg-highlight text-fullWhite hover:bg-darkEarth hover:text-pink transition-colors ease-in-out duration-200 font-bold" +
            (currentStage === "confirm" ? " selected" : null)
          }
          onClick={() => onAddToCart()}
        >
          <img alt="cart" src={cartIcon} id="add-cart" />
          <h5>Agregar al carrito</h5>
        </AddToCartButton>
        <img alt="back" src={back} id="back" onClick={() => setProxyState("currentStage", "pot")} />
        <StartPrompt className={started ? "hide" : null}>
          <h6>Empieza aqui</h6>
          <img alt="point-arrow" src={pointarrow} />
        </StartPrompt>
      </OptionsBar>
    </OptionsContainer>
  )
}

export default OptionsPicker
