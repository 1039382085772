import React from "react"
import Loader from "react-loader-spinner"
import styled from "styled-components/macro"
import { Colors } from "../utils/colors"

const LoadingWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 20;
  width: 100vw;
  height: 99vh;
  height: calc(var(--vh, 1vh) * 71);
  /* background-color: rgb(238 238 238 / 70%); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;

  transition: opacity 0.4s;

  & img {
    width: 150px;
    margin-bottom: 10px;
  }

  &.revealed {
    pointer-events: none;
    opacity: 0;
  }

  & h3 {
    width: 400px;
    max-width: 80%;
    line-height: 28px;
  }
`

const Loading = ({ initial }) => {
  return (
    <LoadingWrapper>
      {/* <img alt="logo" src={logo} style={{ position: 'absolute', width: '230px', top: 0}} /> */}
      {/* <p>Cargando experiencia,<br /> esto puede tomar unos segundos.</p> */}
      <Loader type="Oval" color={Colors.highlight} height={30} width={30} />
    </LoadingWrapper>
  )
}

export default Loading
