import { useSpring } from "@react-spring/core"
import { OrbitControls } from "@react-three/drei"
import { useFrame, useThree } from "@react-three/fiber"
import React, { useEffect, useRef, useState } from "react"
import { useSnapshot } from "valtio"
import { getDisplayOptions, updateCamera } from "../utils/helpers"
import { state } from "../utils/state"

function ControlCamera({ withBase, debugTarget, autoRotate, ...props }) {
  const [targetZoomLevel, setTargetZoomLevel] = useState(0.8)
  const [verticalTarget, setVerticalTarget] = useState(0.25)
  const [animating, setAnimating] = useState(false)
  const { selectedPot, selectedBase, size, withPlant } = useSnapshot(state)
  const orbitRef = useRef()
  const { camera } = useThree()

  const { verticalTargetSpring, zoomLevelSpring } = useSpring({
    verticalTargetSpring: verticalTarget,
    zoomLevelSpring: targetZoomLevel,
  })

  const onAnimationFinished = e => {
    // console.log('Done Animating', camera.zoom)
    setAnimating(false)
  }

  useEffect(() => {
    setTargetZoomLevel(getDisplayOptions("zoomLevel") || 1)
    setVerticalTarget(getDisplayOptions("offset") || 0.25)
  }, [size, selectedBase, withPlant, withBase, selectedPot])

  useEffect(() => {
    // console.log(
    //   "Change detected. Target Zoom Level: ",
    //   targetZoomLevel,
    //   ". Target Vertical: ",
    //   verticalTarget
    // );
    setAnimating(true)
  }, [targetZoomLevel, verticalTarget, selectedPot])

  useEffect(() => {
    setAnimating(false)
  }, [])

  useFrame(() => {
    if (animating) {
      updateCamera(camera, orbitRef.current, targetZoomLevel, verticalTarget, zoomLevelSpring, verticalTargetSpring, onAnimationFinished)
    }
  })

  return (
    <>
      {debugTarget && (
        <mesh {...props} scale={[0.1, 0.1, 0.1]} position={orbitRef.current ? orbitRef.current.target || [0, 0.25, 0] : [0, 0.25, 0]}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={"coral"} />
        </mesh>
      )}
      <OrbitControls ref={orbitRef} maxPolarAngle={Math.PI / 2} target={[0, 0.25, 0]} minDistance={0.7} maxDistance={1.1} enablePan={false} />
    </>
  )
}

export default ControlCamera
