import "./App.css";
// import ModelViewer from "./components/ModelViewer";
import Scene from "./Scene";

function App() {
  return (
    <div className="App">
      <Scene />
    </div>
  );
}

export default App;
