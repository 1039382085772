import React, { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useSnapshot } from "valtio";
import { useSpring, animated } from "@react-spring/three";
import { setLoadingState, state } from "../../utils/state";
import { getCurrentBaseScale } from "../../utils/helpers";

export default function ZamiaBases({ loaded, ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/zamia_bases.glb");
  const {
    size,
    selectedBase,
    baseColor,
    modelLoading: { zamia, plants },
  } = useSnapshot(state);
  const withBase = selectedBase !== "None";

  const { baseScale } = useSpring({
    baseScale: withBase
      ? getCurrentBaseScale()
        ? getCurrentBaseScale()
        : [1, 1, 1]
      : [0, 0, 0],
    delay: 100,
    config: { mass: 10, tension: 1000, friction: 200, precision: 0.00001 },
  });

  useEffect(() => {
    if (zamia && plants) {
      console.log("Preloading Bases");
      useGLTF.preload("/zamia_bases.glb");
    }
  }, [zamia]);

  useEffect(() => {
    if (nodes) {
      console.log("Loaded Baseses");
      setLoadingState("bases", true);
    }
  }, [nodes]);

  return (
    <group ref={group} {...props} dispose={null}>
      {/* <animated.mesh
        geometry={nodes.GEO_Small.geometry}
        material={nodes.GEO_Small.material}
        visible={false}
      /> */}
      {/* <mesh
        geometry={nodes.placeholder.geometry}
        material={nodes.placeholder.material}
        position={[0, 0.4, 0]}
        scale={[0.2, 0.4, 0.19]}
        visible={false}
      /> */}
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_Med.geometry}
        material={nodes.GEO_Med.material}
        material-color={baseColor}
        material-envMapIntensity={0.8}
        scale={baseScale}
        visible={size === "med" && selectedBase === "GEO"}
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_Plus.geometry}
        material={nodes.GEO_Plus.material}
        material-color={baseColor}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={size === "large" && selectedBase === "GEO"}
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_Small.geometry}
        material={nodes.GEO_Small.material}
        material-color={baseColor}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={size === "small" && selectedBase === "GEO"}
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.Tripie.geometry}
        material={materials.Matte_Paint}
        material-color={baseColor}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={
          (size === "med" || size === "small") && selectedBase === "Tripie"
        }
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.Bench_1.geometry}
        material={nodes.Bench_1.material}
        material-color={"#15110A"}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={
          (size === "med" || size === "small") && selectedBase === "Bench"
        }
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.Bench_2.geometry}
        material={materials.Light_Wood}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={
          (size === "med" || size === "small") && selectedBase === "Bench"
        }
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.Hexa_1.geometry}
        material={nodes.Hexa_1.material}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={size === "mini" && selectedBase === "H1"}
        rotation={[0, -3.13, 0]}
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.Hexa_2.geometry}
        material={nodes.Hexa_2.material}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={size === "mini" && selectedBase === "H2"}
        rotation={[0, -3.13, 0]}
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.Hexa_3.geometry}
        material={nodes.Hexa_3.material}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={size === "mini" && selectedBase === "H3"}
        rotation={[0, -3.13, 0]}
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.Hexa_4.geometry}
        material={nodes.Hexa_4.material}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={size === "mini" && selectedBase === "H4"}
        rotation={[0, -3.13, 0]}
      />
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.Hexa_5.geometry}
        material={nodes.Hexa_5.material}
        scale={baseScale}
        material-envMapIntensity={0.8}
        visible={size === "mini" && selectedBase === "H5"}
        rotation={[0, -3.13, 0]}
      />
    </group>
  );
}

// useGLTF.preload('/zamia_bases.glb')
