import styled, { css } from "styled-components/macro"
import { Colors } from "../../utils/colors"
import { PotColors } from "../../utils/ProductInfo"

export const OptionsContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  z-index: 10;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;

  & p {
    cursor: pointer;
    position: relative;
  }

  & p::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0px;
    height: 2px;
    width: 100%;
    background-color: ${PotColors[8]};
    opacity: 0;

    transition: opacity 0.4s;
  }

  & p.selected::after {
    opacity: 1;
  }

  & p.pot-type::after {
    opacity: 1;
    background-color: ${PotColors[3]};
  }

  & h6 {
    color: ${Colors.darkEarth};
  }

  & .selected h6 {
    color: white;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  & .scrollable {
    display: flex;
  }

  @media (max-width: 800px) {
    justify-content: flex-start;
    position: relative;
    overflow-x: scroll;

    &.with-words p {
      margin: 10px 15px;
    }
  }
`

export const Button = styled.div`
  position: relative;
  overflow: hidden;
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bg }) => bg || "lightgray"};
  margin: 30px 12px;
  cursor: pointer;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-position: -112px 118px;
  border: 2.5px solid #ffffff;

  transition: 0.4s cubic-bezier(0.425, 0.005, 0, 1);

  &:hover {
    filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.2));
  }

  ${({ size }) =>
    size &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: ${Colors.pink};
        border-radius: 500px;
        width: 73%;
        height: 73%;
      }
    `}

  ${({ size }) =>
    size === "mini" &&
    css`
      &::after {
        width: 20%;
        height: 20%;
      }
    `}

${({ size }) =>
    size === "small" &&
    css`
      &::after {
        width: 50%;
        height: 50%;
      }
    `}

${({ size }) =>
    size === "large" &&
    css`
      &::after {
        display: none;
      }
    `}
`

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  background: white;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 8px 10px;
  box-shadow: -3px 3px 11px -4px rgb(82 82 82);

  & > * {
    margin: 0px;
    font-weight: 400;
  }

  & img {
    width: 13px;
  }

  & img.close {
    width: 8px;
  }

  & img.ar {
    width: 22px;
  }
`

export const ARButton = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 500px;
  background-color: rgb(238 238 238);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25%;
  right: 0;
  left: 0;
  margin: auto;
  box-shadow: -4px 4px 23px -4px rgb(82 82 82);

  & * {
    width: 70%;
    margin-left: 1px;
  }
`

export const OptionsBar = styled(Row)`
  position: relative;
  width: 780px;
  height: 60px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(18px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 70px;

  transition: background 0.4s cubic-bezier(0.425, 0.005, 0, 1);

  & #back {
    height: 55px;
    position: absolute;
    left: 150px;
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.2s 0s cubic-bezier(0.425, 0.005, 0, 1);
  }

  ${({ stage }) =>
    stage === "confirm" &&
    css`
      background: transparent;

      & ${OptionsButton} {
        opacity: 0;
      }

      & #back {
        opacity: 1;
        pointer-events: all;

        transition: opacity 0.7s 0.4s cubic-bezier(0.425, 0.005, 0, 1);
      }
    `}
`

export const AddToCartButton = styled.div`
  position: absolute;
  /* right: -72px; */
  z-index: 20;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  right: -870px;
  left: 0;
  margin: auto;
  color: white;
  border-radius: 500px;
  background: ${Colors.highlight};
  backdrop-filter: blur(18px);

  &.selected {
    width: 315px;
    height: 55px;
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 70px;
    right: 0px;
  }

  & h5 {
    opacity: 0;

    transition: opacity 0.2s 0s cubic-bezier(0.425, 0.005, 0, 1);
  }

  &.selected h5 {
    opacity: 1;

    transition: opacity 0.7s 0.2s cubic-bezier(0.425, 0.005, 0, 1);
  }

  & img {
    position: absolute;
  }

  &.selected img {
    opacity: 0;
  }

  transition: 0.7s cubic-bezier(0.425, 0.005, 0, 1);
`

export const PotOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 131px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */
  cursor: pointer;

  border-radius: 10px;
  margin: 0px 7.5px 23px;

  transition: background 0.4s cubic-bezier(0.425, 0.005, 0, 1);

  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  & h6 {
    font-size: 14px;
  }

  & img {
    height: 55px;
    margin: 12px 0px;
  }
`

export const BaseColors = styled(Row)`
  width: 0px;
  max-width: 414px;
  max-height: 51px;
  height: 51px;

  transition: width 0.8s;
  transition-timing-function: cubic-bezier(0.425, 0.005, 0, 1);
`

export const BaseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 51px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(60px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 52px;
  color: ${Colors.darkEarth};
  margin: 0px 10px;
  cursor: pointer;

  transition: background 0.5s, color 0.5s;
  transition-timing-function: cubic-bezier(0.425, 0.005, 0, 1);

  &.selected {
    background: ${Colors.darkEarth};
    color: white;
  }
`

export const BaseOption = styled(Row)`
  margin-bottom: 26px;

  & ${BaseColors} ${Button} {
    opacity: 0;
    margin: 0px 12px;

    transition: 0.1s 0s;
  }

  &.selected ${BaseButton} {
    background: ${Colors.darkEarth};
    color: white !important;
  }

  &.selected ${BaseColors} {
    width: ${({ width }) => width}px;
  }

  &.selected ${BaseColors} ${Button} {
    opacity: 1;

    transition: opacity 0.6s 0.9s;
  }
`

export const OptionsButton = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 171px;
  height: 48px;
  background: transparent;
  border-radius: 70px;
  color: ${Colors.darkEarth};
  margin: 0px 10px;
  cursor: pointer;

  transition: color 0.9s 0s, opacity 0.4s;
  transition-timing-function: cubic-bezier(0.425, 0.005, 0, 1);

  &.selected {
    color: white;
  }

  ${({ forceBackground }) =>
    forceBackground &&
    css`
      background: ${forceBackground};
      color: white;
    `}

  &.disabled {
    color: rgb(58 53 50 / 30%);
  }

  & img {
    position: absolute;
    right: 29px;
    top: 1px;
    bottom: 0;
    margin: auto;
    opacity: ${({ dirty }) => (dirty ? 1 : 0)};

    transition: opacity 0.9s 0s cubic-bezier(0.425, 0.005, 0, 1);
  }
`

export const SelectionIndicator = styled.div`
  position: absolute;
  left: 29px;
  top: 1px;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  border-radius: 500px;
  background-color: ${({ bg }) => bg || "transparent"};

  ${({ size }) =>
    size &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 1px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        border-radius: 500px;
        background-color: ${({ bg }) => (bg ? Colors.pink : "transparent")};

        transition: transform 0.7s cubic-bezier(0.425, 0.005, 0, 1);
      }
    `}

  ${({ size }) =>
    size === "med" &&
    css`
      &::after {
        transform: scale(0.7);
      }
    `}
  
  ${({ size }) =>
    size === "small" &&
    css`
      &::after {
        transform: scale(0.5);
      }
    `}
  
  ${({ size }) =>
    size === "mini" &&
    css`
      &::after {
        transform: scale(0.2);
      }
    `}
`

export const MovingBackground = styled.div`
  position: absolute;
  left: 18px;
  width: 171px;
  height: 48px;
  border-radius: 70px;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  background-color: ${({ bg }) => bg || Colors.darkEarth};
  color: white;
  transform: translateX(0px);

  ${({ stage }) =>
    (stage === null || stage === "confirm") &&
    css`
      background-color: transparent;
      box-shadow: none;
    `}

  ${({ stage }) =>
    stage === "color" &&
    css`
      transform: translateX(191px);
    `}

${({ stage }) =>
    stage === "size" &&
    css`
      transform: translateX(382px);
    `}

${({ stage }) =>
    stage === "base" &&
    css`
      transform: translateX(573px);
    `}

transition: transform .7s cubic-bezier(.425, .005, 0, 1), background-color .7s cubic-bezier(.425, .005, 0, 1);
`

export const StartPrompt = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -208px;
  top: -81px;
  opacity: 1;
  pointer-events: none;

  transition: opacity 0.7s cubic-bezier(0.425, 0.005, 0, 1);

  & p {
    margin: 0;
  }

  & img {
    margin-left: 90px;
  }

  &.hide {
    opacity: 0;
  }
`
