import React from "react";
// import logo from "../assets/logo.png";
import "../App.css";
import styled from "styled-components/macro";

// import plant from "../models/emmisive.glb";
// import iosPlant from "../models/emmisive.usdz";
// import bg from "../assets/photo_studio_01_2k.hdr";
// import ar from "../assets/open-AR.jpeg";
import { useEffect, useRef, useState } from "react";
// import Loader from "react-loader-spinner";
import { parseColorString, setGlobalVH } from "../utils/helpers";
// import Loading from "./Loading";
import { useSnapshot } from "valtio";
import { setFileNameAndCheck, state } from "../utils/state";

const Prompt = styled.h4`
  position: absolute;
  left: 20px;
  bottom: 15px;
  color: #3a3a3a;
  font-size: 12px;
  margin: 0;
  pointer-events: none;
  line-height: 15px;

  & span {
    color: #eee;
    background: #3a3a3a;
    border-radius: 100%;
    font-size: 7px;
    position: absolute;
    right: -21px;
    top: 0;
    width: 15px;
    height: 15px;
  }
`;

const ModelViewer = ({ gltf, usdz, ...props }) => {
  const viewer = useRef(null);
  const [viewerSet, setViewerSet] = useState(false);
  const { selectedPot, selectedBase, color, withPlant, fileName } =
    useSnapshot(state);

  const startAR = () => {
    console.log("Activating AR with " + fileName);
    viewer.current.activateAR();
  };

  useEffect(() => {
    window.addEventListener("ar-click", startAR);

    return () => window.removeEventListener("ar-click", startAR);
  }, []);

  useEffect(() => {
    let modelViewer = viewer.current;

    let afterLoad = () => {
      console.log("Model Loaded in ModelViewer");
    };

    if (modelViewer && !viewerSet) {
      modelViewer.addEventListener("model-visibility", afterLoad);
      setViewerSet(true);
    }
  }, [viewer, viewerSet]);

  useEffect(() => {
    let modelName =
      selectedPot.toLowerCase() +
      "_" +
      parseColorString(color) +
      "_" +
      selectedBase.toLowerCase() +
      (withPlant ? "_plant" : "");

    console.log("Model to load is " + modelName);
    setFileNameAndCheck(modelName);
  }, [color, selectedBase, selectedPot, withPlant]);

  return (
    <>
      {/* <Loading /> */}
      <model-viewer
        {...props}
        ref={viewer}
        data-js-focus-visible
        src={"/variations/" + fileName + ".glb"}
        ar
        ios-src={"/variations/" + fileName + ".usdz"}
      ></model-viewer>
    </>
  );
};

export default ModelViewer;
