import React, { Suspense, useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"
import Customizer from "./components/Customizer/Customizer"
import OptionsPicker from "./components/OptionPicker/OptionPicker"
import OptionsPickerMobile from "./components/OptionPickerMobile/OptionPickerMobile"

import logo from "./assets/LOGOTIPO CASSU 2019.svg"
import { setGlobalVH } from "./utils/helpers"
import Loading from "./components/Loading"
import { Colors } from "./utils/colors"
import { useSnapshot } from "valtio"
import { setProxyState, state } from "./utils/state"
import CartOverlay from "./components/CartOverlay/CartOverlay"
import Instructions from "./components/Instructions"
import ShopIntro from "./components/ShopIntro"
import { Canvas } from "@react-three/fiber"
import PlantMedium from "./components/models/PlantMedium"
import ZamiaBases from "./components/models/ZamiaBases"
import { Stage } from "@react-three/drei"
import ControlCamera from "./components/ControlCamera"
import Totem from "./components/models/Totem"
import Bombito from "./components/models/Bombito"
import Bombo from "./components/models/Bombo"
import Zamia from "./components/models/Zamia"

const SceneContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  height: calc(var(--variable-vh, 1vh) * 100);
  background-color: ${Colors.pink};

  @media (max-width: 640px) {
    background-color: #eeeeee;
  }
`

export const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 117px;

  @media (max-width: 640px) {
    z-index: 1;
  }
`

const AboutButton = styled.p`
  position: absolute;
  top: 3%;
  left: 4%;
  cursor: pointer;

  @media (max-width: 1200px) {
    display: none;
  }
`

const CartButton = styled.div`
  position: absolute;
  top: 3%;
  right: 4%;
  display: flex;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  @media (max-width: 1200px) {
    top: 3%;
    right: 8%;

    & > p {
      display: none;
    }
  }

  @media (max-width: 640px) {
    z-index: 1;
  }
`

const CartIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 500px;
  background: ${Colors.highlight};
  margin-left: 24px;

  & p {
    margin: 0;
    font-size: 16px;
    line-height: 17px;
    font-weight: bold;
  }
`

const Scene = () => {
  const { cart } = useSnapshot(state)
  const [withBase, setWithBase] = useState(false)
  const [showLoading, setShowLoading] = useState(true)
  const [introDone, setIntroDone] = useState(false)
  const [started, setStarted] = useState(false)
  const [loadCount, setLoadCount] = useState(0)
  const ref = useRef()
  const {
    selectedPot,
    modelLoading: { zamia, bases, plants },
    pickerHidden,
  } = useSnapshot(state)

  useEffect(() => {
    setGlobalVH()

    window.addEventListener("resize", setGlobalVH)

    return () => window.removeEventListener("resize", setGlobalVH)
  }, [])

  const selectModel = () => {
    switch (selectedPot) {
      case "Zamia":
        return (
          <>
            <Zamia withBase={withBase} loaded={() => setLoadCount(loadCount + 1)} />
          </>
        )
      case "Totem":
        return zamia && bases && <Totem />
      case "Bombito":
        return zamia && bases && <Bombito />
      case "Bombo":
        return zamia && bases && <Bombo />
      default:
        return (
          <>
            <Zamia withBase={withBase} loaded={() => setLoadCount(loadCount + 1)} />
          </>
        )
    }
  }

  return (
    <>
      {/* <Canvas
        className={"render md:opacity-100 " + (introDone ? "opacity-100" : "opacity-0")}
        gl={{ antialias: true }}
        frameloop="demand"
        shadows
        dpr={[1, 2.5]}
        camera={{
          position: [0, 0.38, 1.14],
          rotation: [0, 0.01, 0],
          fov: 50,
          zoom: 1,
        }}
      >
        <ambientLight intensity={0.35} />
        <Suspense fallback={null}>{zamia && <PlantMedium />}</Suspense>
        <Suspense fallback={null}>{zamia && plants && <ZamiaBases withBase={withBase} />}</Suspense>
        <Suspense fallback={null}>
          <Stage
            controls={ref}
            preset={"rembrandt"}
            intensity={0.25}
            contactShadow={true}
            shadows={true}
            adjustCamera={false}
            environment={"warehouse"}
          >
            {selectModel()}
          </Stage>
        </Suspense>
        <ControlCamera withBase={withBase} />
      </Canvas> */}
      <SceneContainer>
        {/* {(showLoading || showInitialLoading) && <Loading initial={showInitialLoading} />} */}
        <ShopIntro loading={showLoading} done={() => setIntroDone(true)} introDone={introDone} />
        <AboutButton>Sobre Cassú</AboutButton>
        <Logo alt="logo" src={logo} />
        <CartButton onClick={() => setProxyState("cartOpen", true)}>
          <p>Carro</p>
          <CartIndicator>
            <p>{JSON.parse(cart).length}</p>
          </CartIndicator>
        </CartButton>
        <Instructions show={started} />
        <CartOverlay />
        <Customizer withBase={withBase} setShowLoading={setShowLoading} activated={introDone} started={started} />
        {window.innerWidth > 768 && <OptionsPicker withBase={withBase} setWithBase={setWithBase} setShowLoading={setShowLoading} />}
        {window.innerWidth <= 768 && (
          <OptionsPickerMobile
            withBase={withBase}
            setWithBase={setWithBase}
            setShowLoading={setShowLoading}
            started={started}
            setStarted={setStarted}
          />
        )}
      </SceneContainer>
    </>
  )
}

export default Scene
