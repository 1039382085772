/* eslint-disable no-useless-concat */
import React, { useRef } from "react"
import PropTypes from "prop-types"
import ValidationMessage from "./ValidationMessage"

// @todo: Figure out keypresses

/**
 * Basic Input Field
 */
const TextField = ({
  value,
  setValue,
  label,
  type,
  hideLabel,
  disabled,
  conditions,
  iconPrefix,
  iconSuffix,
  onBlur,
  clearInput,
  placeholder,
  ...props
}) => {
  const { className, ...addonProps } = props
  const element = useRef()

  const isInvalid = () => {
    return conditions.some(c => c.isInvalid)
  }

  const clear = () => {
    element.current.value = null
    clearInput()
  }

  return (
    <div {...addonProps} className={(disabled ? "opacity-40" : "") + " " + className}>
      <label className={hideLabel ? "block pl-4 text-sm font-medium text-gray-400" : "block pl-4 text-sm font-medium text-gray-400"}>{label}</label>
      <div className="mt-2 relative">
        {iconPrefix && <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">{iconPrefix}</div>}
        <input
          ref={element}
          placeholder={placeholder}
          className={
            (iconPrefix ? "pl-12" : "pl-4") +
            " " +
            "focus:outline-none focus:ring focus:ring-focus pr-10 bg-transparent rounded-3xl border border-pink h-12 block w-full"
          }
          disabled={disabled}
          type={type}
          id={label}
          onChange={setValue}
          onBlur={onBlur}
        />
        {clearInput && value.length > 0 && iconSuffix && (
          <div className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer" role="button" onClick={clear}>
            {iconSuffix}
          </div>
        )}
      </div>
      {isInvalid() && <ValidationMessage className="pl-4 my-2" conditions={conditions} />}
    </div>
  )
}

TextField.propTypes = {
  /**
   * The text managed by the parent component
   */
  value: PropTypes.string,
  /**
   * Function to control model in parent component
   */
  setValue: PropTypes.func,
  /**
   * Identifier
   */
  label: PropTypes.string,
  /**
   * Placeholder text inside the input
   */
  placeholder: PropTypes.string,
  /**
   * Type of the input field
   */
  type: PropTypes.string,
  /**
   * Set to true if the label should come first
   */
  hideLabel: PropTypes.bool,
  /**
   * Control weather input should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * A list of conditions to be met for validty
   */
  conditions: PropTypes.array,
  /**
   * Function to execute when input is blured
   */
  onBlur: PropTypes.func,
  /**
   * Optional function to fully clear input
   */
  clearInput: PropTypes.func,
  /**
   * Icon that accompanies the input on the left
   */
  iconPrefix: PropTypes.node,
  /**
   * Icon that accompanies the input on the right
   */
  iconSuffix: PropTypes.node,
}

TextField.defaultProps = {
  value: "",
  label: "",
  hideLabel: false,
  type: "text",
  conditions: [],
  onBlur: () => null,
  clearInput: () => null,
}

export default TextField
